import { Path } from "app/path";
import { selectAccount } from "app/redux/accountSlice";
import {
  createAppointment,
  deleteAppointment,
  deleteAppointmentGroup,
  updateAppointment,
} from "app/redux/appointmentsSlice";
import { selectBusinessServices } from "app/redux/businessServicesSlice";
import { searchClients, selectClients } from "app/redux/clientsSlice";
import { selectEmployees } from "app/redux/employeesSlice";
import {
  addAppointmentRecurrencyException,
  createAppointmentFromRecurrence,
  makeAppointmentRecurrence,
  RecurrenceFrequency,
  selectSchedules,
  stopAppointmentRecurrence,
} from "app/redux/schedulesSlice";
import { selectShops } from "app/redux/shopsSlice";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { getFormValues } from "app/utils/getFormValues";
import { useQuery } from "app/utils/useQuery";
import {
  addMinutes,
  differenceInHours,
  differenceInMinutes,
  format,
  parse,
  startOfDay,
} from "date-fns";
import { find, findIndex, intersectionBy, sortBy, uniqBy } from "lodash";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useReadAppointmentScreen = ({
  selectedAppointmentEvent,
  getSchedulesOfEmployees,
  toggleAppointmentDrawer,
  setPendingAppointment,
  onMakeAppointmentRepeating,
  onEditAppointmentRepeating,
  onStopAppointmentRepeating,
  closeDrawer,
}: {
  selectedAppointmentEvent: any;
  getSchedulesOfEmployees: ({ currentDate }: { currentDate?: Date }) => void;
  toggleAppointmentDrawer: (open: boolean) => void;
  setPendingAppointment: (appointment: any) => void;
  closeDrawer: () => void;
  onMakeAppointmentRepeating: (data: {
    appointmentGroup: any;
    onSave: (frequency: RecurrenceFrequency, interval: number) => Promise<void>;
  }) => void;
  onEditAppointmentRepeating: (data: {
    selectedClient: any;
    selectedServices: any[];
    parsedDate: Date;
    parsedTime: Date;
    onUpdateThisAppointmentOnly: () => Promise<void>;
    onUpdateAllFutureAppointments: () => Promise<void>;
  }) => void;
  onStopAppointmentRepeating: (data: {
    appointmentGroup: any;
    onSave: () => Promise<void>;
  }) => void;
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const currentDate = query.get("date") as string;
  const appointmentId = query.get("appointmentId") as string;
  const phantomId = query.get("phantomId") as string;
  const navigate = useNavigate();
  const account = useSelector(selectAccount);
  const employees = useSelector(selectEmployees);
  const employee = selectedAppointmentEvent.employee;
  const isRepeating =
    !!selectedAppointmentEvent?.appointment?.appointmentGroup
      ?.appointmentRecurrenceId;
  const businessServices = useSelector(selectBusinessServices);
  const { clients } = useSelector(selectClients);
  const [isEditing, setIsEditing] = useState(false);
  const schedules = useSelector(selectSchedules);
  const [editingEmployeeInServiceIndex, setEditingEmployeeInServiceIndex] =
    useState<number | null>(null);
  const [isDateInputFocused, setIsDateInputFocused] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    selectedAppointmentEvent.start
  );
  const [originalSelectedServices, setOriginalSelectedServices] = useState<
    any[]
  >([]);
  const employeeSelectRef = useRef<any>(null);
  const [availableServices, setAvailableServices] = useState(businessServices);
  const [selectedClient, setSelectedClient] = useState<any>(
    selectedAppointmentEvent.client
  );
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [serviceSelectValue, setServiceSelectValue] = useState<any>(null);
  // const [existingServices, setExistingServices] = useState<any[]>([]);

  const onHandleClientChange = (selectedOption: any) => {
    setSelectedClient({
      ...selectedOption,
      id: selectedOption.value,
      name: selectedOption.label,
    });
  };

  const getSpecificAppointmentGroup = ({
    appointmentId,
    phantomId,
    specificDate,
  }: {
    appointmentId?: string;
    phantomId?: string;
    specificDate?: string;
  }) => {
    const currentSchedule = specificDate
      ? schedules[specificDate]
      : schedules[currentDate] || {};

    // Extract all appointment groups from each employee's schedule

    const allAppointmentGroups = Object.values(currentSchedule)
      .flatMap((schedule: any) => {
        return schedule.appointmentGroups.map((group: any) => {
          return {
            ...group,
            appointments:
              group.appointments.length > 0
                ? group.appointments
                : schedule.appointments,
          };
        });
      })
      .filter((group: any) => group); // Filter out any undefined or null values

    // Ensure the appointment groups are unique by their ID
    const uniqueAppointmentGroups = [
      ...uniqBy(allAppointmentGroups, "id"),
      ...uniqBy(allAppointmentGroups, "phantomId"),
    ];

    const appointmentsGroupToShow = find(
      uniqueAppointmentGroups,
      (group: any) => {
        // **If phantomId is provided, ensure we find the right phantom group**
        if (phantomId && group.phantomId) {
          return group.appointments.some(
            (appointment: any) => appointment.phantomId === phantomId
          );
        } else {
          // **If appointmentId is provided, find it normally**
          return group.appointments.some(
            (appointment: any) => appointment.id === Number(appointmentId)
          );
        }
      }
    );

    const sortedAppointmentsByStartTime = sortBy(
      appointmentsGroupToShow?.appointments,
      (appointment: any) => {
        return new Date(appointment.startTime).getTime();
      }
    );

    return {
      ...appointmentsGroupToShow,
      appointments: sortedAppointmentsByStartTime,
    };
  };

  const handleClientSearch = async (inputValue: string, callback: any) => {
    if (inputValue.length > 2) {
      try {
        const response = await dispatch(
          searchClients(inputValue) as any
        ).unwrap();
        const results =
          response.clients?.map((c: any) => ({
            value: c.id,
            label: c.name,
            phone: c.phone,
            email: c.email,
            createdAt: c.createdAt,
            avatar: c.avatar,
          })) || [];
        callback(results);
      } catch (error) {
        console.error("Error searching clients:", error);
      }
    } else {
      return [];
    }
  };

  const updateSelectedServicesWithStartTimes = ({
    appointmentId,
    setSelectedServices,
  }: {
    appointmentId: string;
    setSelectedServices: React.Dispatch<React.SetStateAction<any[]>>;
  }) => {
    const appointmentsGroupToShow = getSpecificAppointmentGroup({
      appointmentId,
      phantomId,
    });

    if (appointmentsGroupToShow) {
      const servicesWithStartTimes =
        appointmentsGroupToShow.appointments.flatMap((appointment: any) => {
          // Parse the original startTime considering UTC offset
          let currentStartTime = preserveUtcTimeToLocal(appointment.startTime);

          return appointment.services.map((service: any) => {
            // Directly format the start time for display
            const startAtTime = format(currentStartTime, "hh:mm a");
            // Update currentStartTime for the next service
            currentStartTime = addMinutes(currentStartTime, service.duration);

            return {
              ...service,
              startAtTime,
            };
          });
        });

      setSelectedServices(servicesWithStartTimes);
      setOriginalSelectedServices(servicesWithStartTimes);
    }
  };

  const onRemoveService = (index: number) => {
    const updatedServices = [...selectedServices];
    updatedServices.splice(index, 1); // Remove the service at the specified index

    // Recalculate start times for the remaining services
    let lastServiceEndTime: Date | undefined;

    const servicesWithUpdatedTimes = updatedServices.map((service, idx) => {
      if (idx === 0) {
        // If it's the first service, set the start time based on the appointment start time
        lastServiceEndTime = preserveUtcTimeToLocal(
          getSpecificAppointmentGroup({
            appointmentId: selectedAppointmentEvent.appointment.id,
            phantomId: selectedAppointmentEvent.appointment.phantomId,
          }).appointments[0].startTime
        );
      }

      const serviceStartTime = lastServiceEndTime
        ? lastServiceEndTime
        : new Date();

      // Calculate the next service's end time
      lastServiceEndTime = addMinutes(serviceStartTime, service.duration);

      // Update the service with the new start time
      return {
        ...service,
        startAtTime: format(serviceStartTime, "hh:mm a"),
      };
    });

    setSelectedServices(servicesWithUpdatedTimes);
  };

  const onHandleAddService = (selectedOption: any) => {
    const employeeService = find(
      employee!.services,
      (service) => service.businessService.id === selectedOption.value
    );

    if (!employeeService) {
      console.error("Employee service not found");
      return;
    }

    // Calculate the start time for the new service
    let lastServiceEndTime: Date | undefined;

    if (selectedServices.length > 0) {
      const lastService = selectedServices[selectedServices.length - 1];

      lastServiceEndTime = addMinutes(
        parse(lastService.startAtTime, "hh:mm a", new Date()),
        lastService.duration
      );
    } else {
      // If no services exist, use the start time of the appointment
      try {
        lastServiceEndTime = preserveUtcTimeToLocal(
          getSpecificAppointmentGroup({
            appointmentId: selectedAppointmentEvent.appointment.id,
            phantomId: selectedAppointmentEvent.appointment.phantomId,
          }).appointments[0].startTime
        );
      } catch (error) {
        console.error("Error getting appointment start time:", error);
        lastServiceEndTime = new Date(); // Fallback to current date/time
      }
    }

    const newServiceStartTime = lastServiceEndTime;

    const newServiceWithTime = {
      ...employeeService,
      startAtTime: format(newServiceStartTime, "hh:mm a"), // Format the start time
    };

    // Add the new service with the start time to the selected services list
    setSelectedServices([...selectedServices, newServiceWithTime]);

    const updatedAvailableServices = availableServices.map((category) => ({
      ...category,
      services: category.services.filter(
        (service: any) => service.id !== selectedOption.value
      ),
    }));

    setAvailableServices(updatedAvailableServices);
    setServiceSelectValue(null); // Clear the Select component
  };

  const handleEmployeeChange = (serviceIndex: number, selectedOption: any) => {
    const updatedServices = [...selectedServices];
    const newEmployee = employees.find(
      (emp) => emp.id === selectedOption.value
    );
    const newEmployeeService = newEmployee?.services.find(
      (service) =>
        service.businessService.id ===
        updatedServices[serviceIndex].businessService.id
    );
    updatedServices[serviceIndex] = {
      ...updatedServices[serviceIndex],
      employee: newEmployee,
      id: newEmployeeService.id,
      price: newEmployeeService.price,
      priceCents: newEmployeeService.priceCents,
      status: newEmployeeService.status,
      duration: newEmployeeService.duration,
    };
    setSelectedServices(updatedServices);
    setTimeout(() => {
      setEditingEmployeeInServiceIndex(null);
    }, 0);
  };

  // Assume these imports are correct based on your project structure
  const onUpdateAppointment = async (event: any) => {
    event.preventDefault();

    try {
      if (selectedServices.length === 0) {
        return toast.error("Please select at least one service");
      }

      // Extract form values
      const { startTime } = getFormValues(event.target);

      // Format and parse dates
      const formattedDate = format(selectedDate, "yyyy-MM-dd");
      const parsedDate = parse(formattedDate, "yyyy-MM-dd", new Date());
      const parsedTime = parse(startTime, "HH:mm", parsedDate);
      let currentStartDateTime = new Date(
        parsedDate.getFullYear(),
        parsedDate.getMonth(),
        parsedDate.getDate(),
        parsedTime.getHours(),
        parsedTime.getMinutes()
      );

      const appointmentGroup = getSpecificAppointmentGroup({
        appointmentId: selectedAppointmentEvent.appointment.id,
        phantomId: selectedAppointmentEvent.appointment.phantomId,
        specificDate: format(selectedAppointmentEvent.start, "yyyy-MM-dd"),
      });

      // Extract all appointment groups from each employee's schedule
      const appointmentGroupId = appointmentGroup.id;

      const appointmentData = selectedServices.map((service: any) => {
        // Calculate the end time by adding service duration to currentStartDateTime
        const endDateTime = addMinutes(currentStartDateTime, service.duration);

        const payload = {
          startTime: format(currentStartDateTime, "yyyy-MM-dd HH:mm:ss"),
          endTime: format(endDateTime, "yyyy-MM-dd HH:mm:ss"),
          clientId: selectedClient.id,
          shopId: Number(query.get("shopId")),
          email: selectedClient.email,
          name: selectedClient.name,
          employeeId: service.employee.id,
          serviceIds: [service.id],
          appointmentGroupId: appointmentGroupId,
        } as any;

        currentStartDateTime = endDateTime;

        return payload;
      });

      if (isRepeating) {
        const isPhantom = !!appointmentGroup.phantomId;

        if (isPhantom) {
          const data = {
            appointmentRecurrenceId: appointmentGroup.appointmentRecurrence.id,
            payload: {
              appointmentData: appointmentData,
              updateBlueprint: false,
              appointmentGroupId: appointmentGroup.id,
              frequency: appointmentGroup.appointmentRecurrence.frequency,
              interval: appointmentGroup.appointmentRecurrence.interval,
            },
          };

          return onEditAppointmentRepeating({
            selectedServices,
            parsedDate,
            parsedTime,
            selectedClient,
            onUpdateThisAppointmentOnly: async () => {
              data.payload.updateBlueprint = false;

              await dispatch(
                createAppointmentFromRecurrence(data) as any
              ).unwrap();

              getSchedulesOfEmployees({
                currentDate: startOfDay(selectedDate),
              });
              setPendingAppointment(null);
              setIsEditing(false);
              toast.success("Appointment updated successfully");
            },
            onUpdateAllFutureAppointments: async () => {
              // TODO: We may need to update the non phantom group as well (the original appointment)
              data.payload.updateBlueprint = true;

              await dispatch(
                createAppointmentFromRecurrence(data) as any
              ).unwrap();

              getSchedulesOfEmployees({
                currentDate: startOfDay(selectedDate),
              });
              setPendingAppointment(null);
              setIsEditing(false);
              toast.success("Appointment updated successfully");
            },
          });
        } else {
          return onEditAppointmentRepeating({
            selectedServices,
            parsedDate,
            parsedTime,
            selectedClient,
            onUpdateThisAppointmentOnly: async () =>
              onUpdateNonPhantomAppointment(appointmentData),
            onUpdateAllFutureAppointments: async () => {
              await onUpdateNonPhantomAppointment(appointmentData);

              await dispatch(
                createAppointmentFromRecurrence({
                  appointmentRecurrenceId:
                    appointmentGroup.appointmentRecurrence.id,
                  payload: {
                    appointmentData: appointmentData,
                    updateBlueprint: true,
                    appointmentGroupId: appointmentGroup.id,
                    frequency: appointmentGroup.appointmentRecurrence.frequency,
                    interval: appointmentGroup.appointmentRecurrence.interval,
                  },
                }) as any
              ).unwrap();
            },
          });
        }
      }

      return onUpdateNonPhantomAppointment(appointmentData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to update appointment. Please try again.");
    }
  };

  const onUpdateNonPhantomAppointment = async (appointmentData: any[]) => {
    try {
      const appointmentsGroupToShow = getSpecificAppointmentGroup({
        appointmentId: selectedAppointmentEvent.appointment.id,
        phantomId: selectedAppointmentEvent.appointment.phantomId,
        specificDate: format(selectedAppointmentEvent.start, "yyyy-MM-dd"),
      });

      // Extract all appointment groups from each employee's schedule
      const existingAppointments = appointmentsGroupToShow.appointments;

      // Prepare lists to track operations
      const updatePromises = [];
      const createPromises = [];
      const deletePromises: any = [];

      for (const appointment of appointmentData) {
        const existingAppointment = existingAppointments.find((appt: any) =>
          appt.services.some(
            (existingService: any) =>
              existingService.id === appointment.serviceIds[0]
          )
        );

        if (existingAppointment) {
          // Update existing appointment
          updatePromises.push(
            dispatch(
              updateAppointment({
                ...appointment,
                appointmentId: existingAppointment.id,
              }) as any
            ).unwrap()
          );
        } else {
          // Create new appointment
          createPromises.push(
            dispatch(createAppointment(appointment) as any).unwrap()
          );
        }
      }

      // Handle deleting appointments where all services are removed
      const serviceIdsInSelectedServices = selectedServices.map(
        (service) => service.id
      );
      const appointmentsToDelete = existingAppointments.filter(
        (appt: any) =>
          !appt.services.some((existingService: any) =>
            serviceIdsInSelectedServices.includes(existingService.id)
          )
      );

      appointmentsToDelete.forEach((appt: any) => {
        deletePromises.push(
          dispatch(deleteAppointment(appt.id) as any).unwrap()
        );
      });

      // Execute all promises
      await Promise.all([
        ...updatePromises,
        ...createPromises,
        ...deletePromises,
      ]);

      getSchedulesOfEmployees({
        currentDate: startOfDay(selectedDate),
      });
      setPendingAppointment(null);
      setIsEditing(false);

      toast.success("Appointment updated successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update appointment. Please try again.");
    }
  };

  const onDeleteAppointmentGroup = async () => {
    const isPhantom = !!selectedAppointmentEvent.appointment.phantomId;

    const shouldDeleteAppointmentGroup = window.confirm(
      "Are you sure you want to delete this appointment?"
    );
    if (!shouldDeleteAppointmentGroup) {
      return;
    }

    try {
      if (isRepeating && isPhantom) {
        await dispatch(
          addAppointmentRecurrencyException({
            appointmentRecurrenceId:
              selectedAppointmentEvent.appointment.appointmentGroup
                .appointmentRecurrenceId,
            payload: {
              date: selectedDate,
            },
          }) as any
        ).unwrap();
      } else {
        await dispatch(
          deleteAppointmentGroup(
            selectedAppointmentEvent.appointment.appointmentGroup.id
          ) as any
        ).unwrap();
      }
      query.delete("appointmentId");
      query.delete("phantomId");
      navigate(`${Path.HOME}?${query.toString()}`);
      toggleAppointmentDrawer(false);
      await getSchedulesOfEmployees({
        currentDate: startOfDay(selectedDate),
      });

      toast.success("Appointment deleted successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete appointment. Please try again.");
    }
  };

  const cancelEditing = () => {
    setSelectedServices(originalSelectedServices);
    setIsEditing(false);
    setPendingAppointment(null);
  };

  useEffect(() => {
    return () => {
      query.delete("appointmentId");
      query.delete("phantomId");
      navigate(`${Path.HOME}?${query.toString()}`);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        employeeSelectRef.current &&
        !employeeSelectRef.current.contains(event.target as Node)
      ) {
        setEditingEmployeeInServiceIndex(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [employeeSelectRef]);

  useEffect(() => {
    if (selectedAppointmentEvent) {
      updateSelectedServicesWithStartTimes({
        appointmentId,
        setSelectedServices,
      });
      setSelectedClient(selectedAppointmentEvent.client);
    }
    setSelectedClient(selectedAppointmentEvent.client);
  }, [selectedAppointmentEvent]);

  useEffect(() => {
    if (employee && businessServices.length > 0) {
      const updatedAvailableServices = businessServices.map((category) => ({
        ...category,
        services: category.services.filter(
          (service: any) =>
            // Filter out services that are already selected
            !selectedServices.some(
              (selectedService) =>
                selectedService.businessService.id === service.id
            )
        ),
      }));
      setAvailableServices(updatedAvailableServices);
    }
  }, [employee, businessServices, selectedServices]);

  const onDateChange = async (dateValue: any) => {
    if (dateValue === selectedAppointmentEvent.start) {
      return;
    }

    await getSchedulesOfEmployees({
      currentDate: startOfDay(dateValue),
    });

    const appointmentGroup = getSpecificAppointmentGroup({
      appointmentId: selectedAppointmentEvent.appointment.id,
      phantomId: selectedAppointmentEvent.appointment.phantomId,
      specificDate: format(selectedAppointmentEvent.start, "yyyy-MM-dd"),
    });

    const startTimeElement = document.querySelector("#startTime") as any;
    const startTime = startTimeElement.value || "";
    const formattedStartDate = format(dateValue, "yyyy-MM-dd");
    const endTime = addMinutes(
      new Date(`${formattedStartDate}T${startTime}`),
      differenceInMinutes(
        appointmentGroup.appointments[appointmentGroup.appointments.length - 1]
          .endTime,
        appointmentGroup.appointments[0].startTime
      )
    );

    query.delete("date");
    query.set("date", format(dateValue, "yyyy-MM-dd"));
    navigate(`${Path.HOME}?${query.toString()}`);

    setPendingAppointment({
      ...selectedAppointmentEvent,
      pending: true,
      title: `Edit ${selectedClient.name}'s appointment`,
      start: new Date(`${formattedStartDate}T${startTime}`),
      end: new Date(endTime),
    });
  };

  const onTimeChange = (event: any) => {
    const appointmentGroup = getSpecificAppointmentGroup({
      appointmentId: selectedAppointmentEvent.appointment.id,
      phantomId: selectedAppointmentEvent.appointment.phantomId,
      specificDate: format(selectedAppointmentEvent.start, "yyyy-MM-dd"),
    });
    const formattedStartDate = format(selectedDate, "yyyy-MM-dd");
    const formattedStartDateTime = `${formattedStartDate}T${event.target.value}`;
    const endTime = addMinutes(
      new Date(formattedStartDateTime),
      differenceInMinutes(
        appointmentGroup.appointments[appointmentGroup.appointments.length - 1]
          .endTime,
        appointmentGroup.appointments[0].startTime
      )
    );
    setPendingAppointment({
      ...selectedAppointmentEvent,
      title: `Edit ${selectedClient.name}'s appointment`,
      start: new Date(formattedStartDateTime),
      pending: true,
      end: new Date(endTime),
    });
  };

  const handleMakeRepeating = () => {
    const appointmentGroup = getSpecificAppointmentGroup({
      appointmentId: selectedAppointmentEvent.appointment.id,
      phantomId: selectedAppointmentEvent.appointment.phantomId,
    });

    closeDrawer();
    onMakeAppointmentRepeating({
      appointmentGroup,
      onSave: async (frequency: RecurrenceFrequency, interval: number) => {
        await dispatch(
          makeAppointmentRecurrence({
            appointmentGroupId: appointmentGroup.id,
            frequency,
            interval,
          }) as any
        ).unwrap();

        toast.success(
          `Appointment is now repeating every ${interval} week${
            interval > 1 ? "s" : ""
          }`
        );

        getSchedulesOfEmployees({
          currentDate: startOfDay(selectedDate),
        });
      },
    });
  };
  const handleStopRepeating = () => {
    const appointmentGroup = getSpecificAppointmentGroup({
      appointmentId: selectedAppointmentEvent.appointment.id,
      phantomId: selectedAppointmentEvent.appointment.phantomId,
    });

    closeDrawer();
    onStopAppointmentRepeating({
      appointmentGroup,
      onSave: async () => {
        await dispatch(
          stopAppointmentRecurrence({
            appointmentRecurrenceId: appointmentGroup.appointmentRecurrence.id,
            date: selectedDate,
          }) as any
        ).unwrap();

        toast.success("Appointment is no longer repeating");

        getSchedulesOfEmployees({
          currentDate: startOfDay(selectedDate),
        });
      },
    });
  };

  return {
    isEditing,
    setIsEditing,
    onUpdateAppointment,
    selectedClient,
    setSelectedClient,
    onHandleClientChange,
    clients,
    selectedServices,
    setSelectedServices,
    editingEmployeeInServiceIndex,
    setEditingEmployeeInServiceIndex,
    employeeSelectRef,
    employees,
    handleEmployeeChange,
    serviceSelectValue,
    onHandleAddService,
    availableServices,
    selectedDate,
    setSelectedDate,
    isDateInputFocused,
    isRepeating,
    setIsDateInputFocused,
    onRemoveService,
    getSpecificAppointmentGroup,
    cancelEditing,
    onDeleteAppointmentGroup,
    handleClientSearch,
    onTimeChange,
    onDateChange,
    handleMakeRepeating,
    handleStopRepeating,
  };
};
