import { RecurrenceFrequency } from "app/redux/schedulesSlice";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";

function useMakeRepeatBlockTime({
  blockTime,
  onOpenChange,
  onSave,
}: {
  blockTime: any;
  onOpenChange: (isOpen: boolean) => void;
  onSave?: (frequency: RecurrenceFrequency, interval: number) => Promise<void>;
}) {
  const [isRepeating, setIsRepeating] = useState<boolean>(true);
  const [days, setDays] = useState(1);

  const blockTimeDetails = useMemo(() => {
    if (!blockTime) {
      return {
        date: "",
        time: "",
        employee: null,
        name: "",
        shop: {},
      };
    }

    const { start, blockTimes, employee, blockTimeData } = blockTime;
    const formattedDate = start ? format(start, "EEE") : "";
    const formattedTime = start ? format(start, "h:mm a") : "";

    return {
      date: formattedDate,
      time: formattedTime,
      employee,
      name: blockTimeData?.name,
    };
  }, [blockTime]);

  const confirmRepeat = () => {
    if (days < 1) {
      return toast.error("Unavailability frequency has to be at least 1 day");
    }
    if (!isRepeating) {
      return onOpenChange(false);
    }
    onOpenChange(false);
    onSave?.(RecurrenceFrequency.DAILY, days);
  };

  return {
    isRepeating,
    setIsRepeating,
    days,
    setDays,
    blockTimeDetails,
    confirmRepeat,
  };
}

export default useMakeRepeatBlockTime;
