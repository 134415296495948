"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * AsInput — https://app.subframe.com/feb895689ba0/library?component=AsInput_f45dfa71-d2b1-4826-bd47-2993766763a3
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface AsInputRootProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leading?: string;
  error?: boolean;
  trailing?: string;
  disabled?: boolean;
  showLeadingIcon?: boolean;
  placeholder?: string;
  leadingIcon?: SubframeCore.IconName;
  className?: string;
  containerClassName?: string;
  showDisabledStyles?: boolean;
  trailingComponent?: any;
  textPosition?: "left" | "right";
  label?: string;
  required?: boolean;
}

const AsInputRoot = React.forwardRef<HTMLInputElement, AsInputRootProps>(
  function AsInputRoot(
    {
      leading,
      trailing,
      trailingComponent,
      error = false,
      disabled = false,
      placeholder,
      showDisabledStyles = true,
      textPosition = "right",
      showLeadingIcon = false,
      leadingIcon = "FeatherPlus",
      required = false,
      containerClassName,
      label,
      className,
      ...otherProps
    }: AsInputRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex flex-col",
          containerClassName
        )}
      >
        {label && (
          <p className="text-zinc-500 text-sm mb-2">
            {label} {required && <span className="text-red-400">*</span>}{" "}
          </p>
        )}
        <div
          className={SubframeCore.twClassNames(
            "group/f45dfa71 flex overflow-hidden h-12 w-full items-center gap-1 rounded-md border border-solid border-neutral-50 bg-white hover:border hover:border-solid hover:border-neutral-200 active:border active:border-solid active:border-neutral-500 focus-within:border focus-within:border-solid focus-within:border-neutral-500",
            {
              "border border-solid border-neutral-400 bg-neutral-200":
                disabled && showDisabledStyles,
              "border border-solid border-error-500": error,
            },
            className
          )}
        >
          {/* Leading Icon */}
          {showLeadingIcon && (
            <SubframeCore.Icon
              className={SubframeCore.twClassNames(
                "text-body font-body text-neutral-900",
                { "text-neutral-400": disabled && showDisabledStyles }
              )}
              name={leadingIcon}
            />
          )}

          {/* Leading Text */}
          {leading && (
            <span
              className={SubframeCore.twClassNames(
                "text-[14px] font-[500] leading-[20px] text-nowrap ml-2 text-neutral-500 group-active/f45dfa71:text-neutral-700 group-focus-within/f45dfa71:text-neutral-700",
                { "text-neutral-400": disabled && showDisabledStyles }
              )}
            >
              {leading}
            </span>
          )}

          {/* Input Element */}
          <input
            className={SubframeCore.twClassNames(
              "flex-grow w-full bg-transparent h-full text-body font-body outline-none px-3 text-right font-[500] leading-[20px] mobile:text-[16px] text-neutral-700 group-active/f45dfa71:text-neutral-700 group-focus-within/f45dfa71:text-neutral-700 placeholder:text-neutral-500 placeholder:font-medium",
              {
                "text-neutral-400": disabled && showDisabledStyles,
                "text-left": textPosition === "left",
                "text-right": textPosition === "right",
              }
            )}
            ref={ref}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            {...otherProps} // Spread input HTML attributes here
          />

          {/* Trailing Text */}
          {trailing && (
            <span
              className={SubframeCore.twClassNames(
                "text-[14px] font-[400] leading-[20px] text-neutral-500 text-right",
                { "text-neutral-400": disabled && showDisabledStyles }
              )}
            >
              {trailing}
            </span>
          )}

          {trailingComponent && (
            // <div className="-mr-3"> {trailingComponent} </div>
            <div> {trailingComponent} </div>
          )}
        </div>
      </div>
    );
  }
);

export const reactSelectStyles = ({
  isOpen,
  borderContainer,
  controlStyles,
}: {
  isOpen?: boolean;
  borderContainer?: boolean;
  controlStyles?: any;
}) => ({
  control: (styles: any) => ({
    ...styles,
    border: borderContainer ? "1px solid #F4F4F5" : "none",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    zIndex: 50,
    ...controlStyles,
    // padding: "0",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontWeight: "600",
    color: "#3F3F46",
  }),
  menu: (styles: any) => ({
    ...styles,
    borderColor: "#F1F1F2",
    borderRadius: 8,
    padding: 8,
    gap: 8,
    marginLeft: 8,
    marginRight: 8,
    width: "calc(100% - 16px)",
    zIndex: 100,
  }),
  menuList: (styles: any) => ({
    ...styles,
    gap: 4,
    display: "flex",
    flexDirection: "column",
    zIndex: 100,
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: isOpen ? "#8E8169" : "#444449",
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    color: "#444449",
    fontWeight: state.isSelected ? 500 : 400,
    backgroundColor:
      state.isFocused || state.isHovered
        ? "#F5F3EF !important"
        : state.isSelected
        ? "#F5F3EF !important"
        : "white !important",
    borderRadius: 4,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
    // display: "none",
    // padding: "0 !important",
    color: "black",
    // transform: "translateX(-16px) !important",
    svg: {
      color: "rgb(113, 113, 122)",
      scale: "0.8",
    },
  }),
});

export const AsInput = AsInputRoot;
