import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export const getAppointmentGroup = createAsyncThunk(
  "appointmentGroups/getAppointmentGroup",
  async (appointmentGroupId: number) => {
    const { data } = await http.get(
      `/v1/appointment_groups/${appointmentGroupId}`
    );
    return data;
  }
);
