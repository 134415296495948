import { Divider } from "@nextui-org/react";
import { SectionHeader } from "app/components/sectionHeader";
import { Switch } from "@nextui-org/switch";
import { PlusIcon } from "@heroicons/react/24/solid";
import * as React from "react";
import { useBusinessPolicies } from "./useBusinessPolicies";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { InputEdit } from "app/components/inputEdit/InputEdit";
import { Icon } from "@subframe/core";
import { AsButton, reactSelectStyles } from "app/subframe";
import Select from "react-select";

export interface BusinessPoliciesProps {}

export const BusinessPolicies: React.FC<BusinessPoliciesProps> = (props) => {
  const {
    account,
    onUpdateAccount,
    uploadWaiver,
    onDownloadWaiver,
    uploadConsent,
    onDownloadConsent,
    consentServices,
    onHandleConsentServiceChange,
  } = useBusinessPolicies();

  return (
    <div className="flex flex-col w-full gap-6 pb-6">
      <SectionHeader title="Business Policies" />
      <div className="p-4 flex flex-col rounded border border-neutral-50">
        <p className=" text-zinc-500">Booking policies</p>
        <Divider className="text-zinc-500 mt-4 mb-6" />
        <div className="flex flex-col gap-6">
          <div className="flex gap-16 items-start">
            <div className="flex flex-col gap-1">
              <p className="text-neutral-900">
                Require a credit card for booking
              </p>
              <p className="text-sm text-neutral-500">
                Request a credit card at booking, even if the customer chooses
                to pay in-store.
              </p>
            </div>
            <Switch
              color="success"
              size="sm"
              defaultSelected={account.requireCreditCardForBooking}
              onChange={(event) =>
                onUpdateAccount({
                  requireCreditCardForBooking: event.target.checked,
                  enableCashPaymentForBooking: !event.target.checked
                    ? false
                    : account.enableCashPaymentForBooking,
                })
              }
            />
          </div>
          {account.requireCreditCardForBooking && (
            <FadeInSlide direction="top">
              <div className="flex ml-6 gap-16 items-start">
                <div className="flex flex-col gap-1">
                  <p className="text-neutral-900">Enable cash payment</p>
                  <p className="text-sm text-neutral-500">
                    Allow customers to keep their booking and choose to pay with
                    cash if their credit card can’t be validated.
                  </p>
                </div>
                <Switch
                  color="success"
                  size="sm"
                  defaultSelected={account.enableCashPaymentForBooking}
                  onChange={(event) =>
                    onUpdateAccount({
                      enableCashPaymentForBooking: event.target.checked,
                    })
                  }
                />
              </div>
            </FadeInSlide>
          )}
          <Divider className="text-zinc-500 my-4" />
          <div className="flex gap-16 items-start">
            <div className="flex flex-col gap-1">
              <p className="text-neutral-900">Enable Dual Pricing</p>
              <p className="text-sm text-neutral-500">
                Turn on dual pricing, offering customers a separate price for
                both cash and card payments, giving a 4% discount when not
                paying by credit.
              </p>
            </div>
            <Switch
              color="success"
              size="sm"
              defaultSelected={account.dualPricingEnabled}
              onChange={(event) =>
                onUpdateAccount({
                  dualPricingEnabled: event.target.checked,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="p-4 flex flex-col rounded border border-neutral-50">
        <p className=" text-zinc-500">Marketing policies</p>
        <Divider className="text-zinc-500 mt-4 mb-6" />
        <div className="flex flex-col gap-6">
          <div className="flex gap-16 items-start">
            <div className="flex flex-col gap-1">
              <p className="text-neutral-900">Request a review</p>
              <p className="text-sm text-neutral-500">
                Send a thank-you email half hour post-appointment to request a
                review, encouraging customers to share their experience on
                Google, Yelp, or the platform of your choice.
              </p>
            </div>
            <Switch
              color="success"
              size="sm"
              defaultSelected={account.requestReview}
              onChange={(event) =>
                onUpdateAccount({
                  requestReview: event.target.checked,
                })
              }
            />
          </div>
          {account.requestReview && (
            <FadeInSlide direction="top">
              <div className="flex w-full flex-col items-start gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
                <div className="flex flex-col gap-1 w-full">
                  <InputEdit
                    defaultValue={account.reviewLink || ""}
                    name="reviewLink"
                    label="Your review link"
                    isEditing={account.reviewLink === ""}
                    valueClassName="text-body-bold font-body-bold text-neutral-900"
                    onSave={(value) => onUpdateAccount(value)}
                    truncateLength={48}
                  />
                </div>
              </div>
              {account.reviewLink === "" && (
                <p className="text-sm text-error-600 font-medium mt-4">
                  Please note: To activate this feature, please enter your
                  review link.
                </p>
              )}
            </FadeInSlide>
          )}
        </div>
      </div>
      <div className="p-4 flex flex-col rounded border border-neutral-50">
        <p className=" text-zinc-500">Consent policies</p>
        <Divider className="text-zinc-500 mt-4 mb-6" />
        <div className="flex flex-col gap-6">
          <div className="flex gap-16 items-start">
            <div className="flex flex-col gap-1">
              <p className="text-neutral-900">Request a consent</p>
              <p className="text-sm text-neutral-500">
                Require customers to check an 'I consent' box to complete your
                consent form before their booking is confirmed. This process
                complies with the E-Sign Act, ensuring legally valid electronic
                consent.
              </p>
            </div>
            <Switch
              color="success"
              size="sm"
              defaultSelected={account.consentEnabled}
              onChange={(event) =>
                onUpdateAccount({
                  consentEnabled: event.target.checked,
                })
              }
            />
          </div>
          {account.consentEnabled && (
            <FadeInSlide direction="top">
              <div className="flex gap-16 items-start mb-2">
                <div className="flex flex-col gap-1">
                  <p className="text-neutral-900">Services require consent</p>
                  <p className="text-sm text-neutral-500">
                    Select the services for which you'd like to gather user
                    consent.
                  </p>
                  {/* consentServiceIds, setConsentServiceIds */}
                </div>
              </div>
              <Select
                key={`select-${consentServices.length}`} // Use key to force re-render
                defaultValue={consentServices.map((service) => ({
                  value: service.id,
                  label: service.title,
                }))}
                isMulti
                name="services"
                onChange={onHandleConsentServiceChange}
                options={account.services.map((service) => ({
                  value: service.id,
                  label: service.title,
                }))} // Map account.services into valid options
                className="text-sm"
                placeholder="Select your services"
                classNamePrefix="select"
                styles={reactSelectStyles({})}
              />
              <div className="flex flex-col gap-1">
                <Divider className="text-zinc-500 mt-4 mb-6" />
                <p className="text-neutral-900">Upload your consent form</p>
                <p className="text-sm text-neutral-500">
                  Upload your consent from as a .PDF or .DOC.
                </p>
                <div className="flex gap-4 items-center mt-4 mb-6">
                  {account.consentForm?.url && (
                    <div className="relative group w-[100px] h-[100px]">
                      {/* Document Icon */}
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center rounded border border-gray-300">
                        <Icon name={"FeatherFile"} className="scale-[4]" />
                      </div>

                      {/* Hover Overlay */}
                      <div className="absolute inset-0 bg-black bg-opacity-50 rounded flex flex-col gap-2 items-center justify-center px-1 opacity-0 group-hover:opacity-100 transition-opacity">
                        <a
                          href={account.consentForm.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-neutral-900 text-center font-semibold bg-brand-500 hover:bg-brand-600 px-3 py-1 rounded text-sm w-full"
                        >
                          View
                        </a>
                        <AsButton
                          text="Download"
                          size="xs"
                          className="w-full"
                          onClick={() =>
                            onDownloadConsent(account.consentForm!.id)
                          }
                        />
                      </div>
                      {/* Document Name */}
                      <p className="text-xs text-neutral-900 mt-1 truncate w-full text-center">
                        {account.consentForm.name}
                      </p>
                    </div>
                  )}

                  <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={uploadConsent}
                    className="hidden"
                    id="consent-upload"
                  />
                  <label
                    htmlFor="consent-upload"
                    className="flex flex-col gap-3 w-[100px] h-[100px] bg-zinc-100 items-center justify-center border-dashed border-2 p-[10px] rounded cursor-pointer"
                  >
                    <PlusIcon className="h-5 w-5 stroke-black" />
                    <p className="text-sm">Upload</p>
                  </label>
                </div>
                {!account.consentForm?.url && (
                  <p className="text-sm text-error-600 font-medium">
                    Please note: To activate this feature, please upload a
                    consent document in .PDF or .DOC format.
                  </p>
                )}
              </div>
            </FadeInSlide>
          )}
        </div>
      </div>
      <div className="p-4 flex flex-col rounded border border-neutral-50">
        <p className=" text-zinc-500">Waiver policies</p>
        <Divider className="text-zinc-500 mt-4 mb-6" />
        <div className="flex flex-col gap-6">
          <div className="flex gap-16 items-start">
            <div className="flex flex-col gap-1">
              <p className="text-neutral-900">Request a waiver</p>
              <p className="text-sm text-neutral-500">
                Request customers to complete your waiver after{" "}
                <span className="font-bold">their first booking</span>. An email
                will be sent with instructions.
              </p>
            </div>
            <Switch
              color="success"
              size="sm"
              defaultSelected={account.waiverEnabled}
              onChange={(event) =>
                onUpdateAccount({
                  waiverEnabled: event.target.checked,
                  waiverRequiredForEveryBooking: !event.target.checked
                    ? false
                    : account.waiverRequiredForEveryBooking,
                })
              }
            />
          </div>
          {account.waiverEnabled && (
            <FadeInSlide direction="top">
              <div className="flex ml-6 gap-16 items-start">
                <div className="flex flex-col gap-1">
                  <p className="text-neutral-900">
                    Request a waiver for every booking
                  </p>
                  <p className="text-sm text-neutral-500">
                    Request customers to complete a waiver with every booking.
                  </p>
                </div>
                <Switch
                  color="success"
                  size="sm"
                  defaultSelected={account.waiverRequiredForEveryBooking}
                  onChange={(event) =>
                    onUpdateAccount({
                      waiverRequiredForEveryBooking: event.target.checked,
                    })
                  }
                />
              </div>
              <div className="flex flex-col gap-1">
                <Divider className="text-zinc-500 mt-4 mb-6" />
                <p className="text-neutral-900">Upload your waiver</p>
                <p className="text-sm text-neutral-500">
                  Upload your waiver as a .PDF or .DOC.
                </p>
                <div className="flex gap-4 items-center mt-4 mb-6">
                  {account.waiver && (
                    <div className="relative group w-[100px] h-[100px]">
                      {/* Document Icon */}
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center rounded border border-gray-300">
                        <Icon name={"FeatherFile"} className="scale-[4]" />
                      </div>

                      {/* Hover Overlay */}
                      <div className="absolute inset-0 bg-black bg-opacity-50 rounded flex flex-col gap-2 items-center justify-center px-1 opacity-0 group-hover:opacity-100 transition-opacity">
                        <a
                          href={account.waiver.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-neutral-900 text-center font-semibold bg-brand-500 hover:bg-brand-600 px-3 py-1 rounded text-sm w-full"
                        >
                          View
                        </a>
                        <AsButton
                          text="Download"
                          size="xs"
                          className="w-full"
                          onClick={() => onDownloadWaiver(account.waiver!.id)}
                        />
                      </div>
                      {/* Document Name */}
                      <p className="text-xs text-neutral-900 mt-1 truncate w-full text-center">
                        {account.waiver.name}
                      </p>
                    </div>
                  )}

                  <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={uploadWaiver}
                    className="hidden"
                    id="waiver-upload"
                  />
                  <label
                    htmlFor="waiver-upload"
                    className="flex flex-col gap-3 w-[100px] h-[100px] bg-zinc-100 items-center justify-center border-dashed border-2 p-[10px] rounded cursor-pointer"
                  >
                    <PlusIcon className="h-5 w-5 stroke-black" />
                    <p className="text-sm">Upload</p>
                  </label>
                </div>
                {!account.waiver && (
                  <p className="text-sm text-error-600 font-medium">
                    Please note: To activate this feature, please upload a
                    waiver document in .PDF or .DOC format.
                  </p>
                )}
              </div>
            </FadeInSlide>
          )}
        </div>
      </div>
    </div>
  );
};
