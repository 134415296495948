import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface Schedule {
  employeeId: number;
  employeeName: string;
  employeeUid: string;
  date: string;
  appointments: any[];
  availability: {
    id: number;
    type: string;
    dayOrDate: string;
    timeStart: string;
    timeEnd: string;
    status: string;
    availabilityableType: string;
    availabilityableId: number;
    startOfWeek: string;
  };
}

export interface SchedulesSliceState {
  [date: string]: {
    [employeeId: number]: Schedule;
  };
}

export enum RecurrenceFrequency {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export interface MakeAppointmentGroupRecurrencePayload {
  appointmentGroupId: string;
  frequency: RecurrenceFrequency;
  interval: number;
  startDate?: string;
}

export interface AddExceptionPayload {
  date: string;
}

export interface CreateAppointmentFromRecurrencePayload {
  appointmentGroupId: string;
  frequency: RecurrenceFrequency;
  interval: number;
  updateBlueprint: boolean;
  appointmentData: any[];
}

export interface MakeBlockTimeRecurrencePayload {
  blockTimeId: number;
  frequency: RecurrenceFrequency;
  interval: number;
  startDate?: string;
}

const initialState: SchedulesSliceState = {};

export const getSchedules = createAsyncThunk(
  "schedules/getSchedulesStatus",
  async (
    { employeeIds = [], date }: { employeeIds: number[]; date: Date },
    thunkAPI
  ) => {
    try {
      const { data } = await http.get("/v1/schedules", {
        params: {
          schedules: {
            employeeIds,
            date,
          },
          snakeCase: true,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const makeAppointmentRecurrence = createAsyncThunk(
  "schedules/makeAppointmentRecurrence",
  async (
    {
      appointmentGroupId,
      frequency,
      interval,
      startDate,
    }: MakeAppointmentGroupRecurrencePayload,
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/appointment_recurrences`, {
        appointment_recurrence: {
          appointmentGroupId,
          frequency,
          interval,
          startDate,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || "Unknown error");
    }
  }
);

export const stopAppointmentRecurrence = createAsyncThunk(
  "schedules/stopAppointmentRecurrence",
  async (
    {
      appointmentRecurrenceId,
      date,
    }: { appointmentRecurrenceId: number; date: string },
    thunkAPI
  ) => {
    try {
      const { data } = await http.delete(
        `/v1/appointment_recurrences/${appointmentRecurrenceId}`,
        { params: { date } }
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || "Unknown error");
    }
  }
);

export const addAppointmentRecurrencyException = createAsyncThunk(
  "schedules/addAppointmentRecurrencyException",
  async (
    {
      appointmentRecurrenceId,
      payload,
    }: {
      appointmentRecurrenceId: number;
      payload: AddExceptionPayload;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(
        `/v1/appointment_recurrences/${appointmentRecurrenceId}/add_recurrence_exception`,
        {
          date: payload.date,
        }
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || "Unknown error");
    }
  }
);

export const createAppointmentFromRecurrence = createAsyncThunk(
  "schedules/createAppointmentFromRecurrence",
  async (
    {
      appointmentRecurrenceId,
      payload,
    }: {
      appointmentRecurrenceId: number;
      payload: CreateAppointmentFromRecurrencePayload;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(
        `/v1/appointment_recurrences/${appointmentRecurrenceId}/create_appointment_from_recurrence`,
        {
          appointment_recurrence: {
            appointmentGroupId: payload.appointmentGroupId,
            frequency: payload.frequency,
            interval: payload.interval,
            updateBlueprint: payload.updateBlueprint,
            appointmentData: payload.appointmentData,
          },
        }
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || "Unknown error");
    }
  }
);

export const makeBlockTimeRecurrence = createAsyncThunk(
  "schedules/makeBlockTimeRecurrence",
  async (
    {
      blockTimeId,
      frequency,
      interval,
      startDate,
    }: MakeBlockTimeRecurrencePayload,
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/block_time_recurrences`, {
        blockTimeRecurrence: {
          blockTimeId,
          frequency,
          interval,
          startDate,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || "Unknown error");
    }
  }
);

export const addBlockTimeRecurrencyException = createAsyncThunk(
  "schedules/addBlockTimeRecurrencyException",
  async (
    {
      blockTimeRecurrenceId,
      payload,
    }: {
      blockTimeRecurrenceId: number;
      payload: AddExceptionPayload;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(
        `/v1/block_time_recurrences/${blockTimeRecurrenceId}/add_recurrence_exception`,
        {
          date: payload.date,
        }
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || "Unknown error");
    }
  }
);

export const stopBlockTimeRecurrence = createAsyncThunk(
  "schedules/stopBlockTimeRecurrence",
  async (
    {
      blockTimeRecurrenceId,
      date,
    }: { blockTimeRecurrenceId: number; date: string },
    thunkAPI
  ) => {
    try {
      const { data } = await http.delete(
        `/v1/block_time_recurrences/${blockTimeRecurrenceId}`,
        { params: { date } }
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || "Unknown error");
    }
  }
);

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSchedules.fulfilled, (state, action) => {
      action.payload.forEach((schedule: Schedule) => {
        const { date, employeeId } = schedule;
        if (!state[date]) {
          state[date] = {};
        }
        state[date][employeeId] = schedule;
      });
    });
  },
});

export const selectSchedules = (state: RootState) => state.schedules;
