import { Path } from "app/path";
import {
  createBlockTime,
  deleteBlockTime as deleteBlockTimeFromRedux,
} from "app/redux/blockTimeSlice";
import {
  addBlockTimeRecurrencyException,
  makeBlockTimeRecurrence,
  RecurrenceFrequency,
  selectSchedules,
  stopBlockTimeRecurrence,
} from "app/redux/schedulesSlice";
import { useQuery } from "app/utils/useQuery";
import {
  addMinutes,
  differenceInMinutes,
  format,
  isValid,
  parse,
  set,
  startOfDay,
} from "date-fns";
import { find, flatMap } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const durationOptions = [
  { value: 10, label: "10 minutes", showInList: false },
  { value: 20, label: "20 minutes", showInList: false },
  { value: 30, label: "30 minutes", showInList: true },
  { value: 40, label: "40 minutes", showInList: false },
  { value: 50, label: "50 minutes", showInList: false },
  { value: 60, label: "1 hour", showInList: true },
  { value: 70, label: "1 hour 10 minutes", showInList: false },
  { value: 80, label: "1 hour 20 minutes", showInList: false },
  { value: 90, label: "1 hour 30 minutes", showInList: true },
  { value: 100, label: "1 hour 40 minutes", showInList: false },
  { value: 110, label: "1 hour 50 minutes", showInList: false },
  { value: 120, label: "2 hours", showInList: true },
  { value: 130, label: "2 hours 10 minutes", showInList: false },
  { value: 140, label: "2 hours 20 minutes", showInList: false },
  { value: 150, label: "2 hours 30 minutes", showInList: true },
  { value: 160, label: "2 hours 40 minutes", showInList: false },
  { value: 170, label: "2 hours 50 minutes", showInList: false },
  { value: 180, label: "3 hours", showInList: true },
  { value: 190, label: "3 hours 10 minutes", showInList: false },
  { value: 200, label: "3 hours 20 minutes", showInList: false },
  { value: 210, label: "3 hours 30 minutes", showInList: true },
  { value: 220, label: "3 hours 40 minutes", showInList: false },
  { value: 230, label: "3 hours 50 minutes", showInList: false },
  { value: 240, label: "4 hours", showInList: true },
  { value: 250, label: "4 hours 10 minutes", showInList: false },
  { value: 260, label: "4 hours 20 minutes", showInList: false },
  { value: 270, label: "4 hours 30 minutes", showInList: true },
  { value: 280, label: "4 hours 40 minutes", showInList: false },
  { value: 290, label: "4 hours 50 minutes", showInList: false },
  { value: 300, label: "5 hours", showInList: true },
  { value: 310, label: "5 hours 10 minutes", showInList: false },
  { value: 320, label: "5 hours 20 minutes", showInList: false },
  { value: 330, label: "5 hours 30 minutes", showInList: true },
  { value: 340, label: "5 hours 40 minutes", showInList: false },
  { value: 350, label: "5 hours 50 minutes", showInList: false },
  { value: 360, label: "6 hours", showInList: true },
  { value: 370, label: "6 hours 10 minutes", showInList: false },
  { value: 380, label: "6 hours 20 minutes", showInList: false },
  { value: 390, label: "6 hours 30 minutes", showInList: true },
  { value: 400, label: "6 hours 40 minutes", showInList: false },
  { value: 410, label: "6 hours 50 minutes", showInList: false },
  { value: 420, label: "7 hours", showInList: true },
  { value: 430, label: "7 hours 10 minutes", showInList: false },
  { value: 440, label: "7 hours 20 minutes", showInList: false },
  { value: 450, label: "7 hours 30 minutes", showInList: true },
  { value: 460, label: "7 hours 40 minutes", showInList: false },
  { value: 470, label: "7 hours 50 minutes", showInList: false },
  { value: 480, label: "8 hours", showInList: true },
];

export const useBlockTimeScreen = ({
  blockTime,
  closeDrawer,
  getSchedulesOfEmployees,
  setSelectedBlockTime,
  onMakeBlockTimeRepeating,
  onStopBlockTimeRepeating,
}: {
  blockTime: any;
  closeDrawer: () => void;
  getSchedulesOfEmployees: any;
  setSelectedBlockTime: any;
  onMakeBlockTimeRepeating: any;
  onStopBlockTimeRepeating: any;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const shopId = query.get("shopId");
  const schedules = useSelector(selectSchedules);
  const blockTimeId = query.get("blockTimeId");
  const [isDateInputFocused, setIsDateInputFocused] = React.useState(false);
  const [selectedDate, setSelectedDate] = useState(
    blockTime?.start || new Date()
  );
  const [selectedTime, setSelectedTime] = useState(
    format(blockTime?.start, "HH:mm") || "00:00"
  );
  const [name, setName] = useState("Break");
  const [duration, setDuration] = useState<any>(
    differenceInMinutes(blockTime?.end, blockTime?.start)
  );

  const isRepeating = blockTime?.blockTimeData?.blockTimeRecurrence?.id;

  const onSubmit = async () => {
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    const parsedDate = parse(formattedDate, "yyyy-MM-dd", new Date());
    const parsedTime = parse(selectedTime, "HH:mm", parsedDate);

    // Combine the date and time into a single Date object
    const currentStartDateTime = set(parsedDate, {
      hours: parsedTime.getHours(),
      minutes: parsedTime.getMinutes(),
    });

    const formattedStartTime = format(currentStartDateTime, "yyyy-MM-dd HH:mm");
    const formattedEndTime = format(
      addMinutes(currentStartDateTime, duration),
      "yyyy-MM-dd HH:mm"
    );

    try {
      await dispatch(
        createBlockTime({
          name,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          blockableType: "User",
          blockableId: blockTime.resourceId[0],
          shopId: Number(shopId),
        }) as any
      ).unwrap();
      toast.success(`${name} added successfully`);
      getSchedulesOfEmployees({
        currentDate: startOfDay(selectedDate),
      });
      query.delete("blockTimeId");
      navigate(`${Path.HOME}?${query.toString()}`);
      closeDrawer();
    } catch (error) {
      toast.error("Failed to create unavailability");
      console.log(error);
    }
  };

  const deleteBlockTime = async (id: number) => {
    try {
      if (isRepeating) {
        await dispatch(
          addBlockTimeRecurrencyException({
            blockTimeRecurrenceId:
              blockTime.blockTimeData.blockTimeRecurrence.id,
            payload: {
              date: selectedDate,
            },
          }) as any
        ).unwrap();
      } else {
        await dispatch(deleteBlockTimeFromRedux(id) as any).unwrap();
      }
      toast.success("Time unavailability deleted successfully");
      getSchedulesOfEmployees({
        currentDate: startOfDay(selectedDate),
      });
      query.delete("blockTimeId");
      navigate(`${Path.HOME}?${query.toString()}`);
      closeDrawer();
    } catch (err) {
      toast.error("Failed to delete time unavailability");
      console.log(err);
    }
  };

  const onTimeChange = useCallback(
    (startTime: string) => {
      if (!isValid(blockTime?.start) || !isValid(blockTime?.end)) {
        return;
      }

      // Format the selected date
      const formattedDate = format(new Date(selectedDate), "yyyy-MM-dd");

      // Combine date with new start and end times
      const formattedStartDateTime = `${formattedDate}T${startTime}`;
      const formattedDateEndTime = `${formattedDate}T${format(
        addMinutes(
          formattedStartDateTime,
          differenceInMinutes(blockTime?.end, blockTime?.start)
        ),
        "HH:mm"
      )}`; // Combine both

      // Convert to Date objects
      const dateTimeStartObject = new Date(formattedStartDateTime);
      const dateTimeEndObject = new Date(formattedDateEndTime);

      // Update the pending appointment state
      setSelectedBlockTime({
        ...blockTime,
        start: dateTimeStartObject,
        end: dateTimeEndObject,
      });
      setSelectedTime(startTime);
    },
    [blockTime]
  );

  const handleMakeRepeating = () => {
    closeDrawer();
    onMakeBlockTimeRepeating({
      blockTime,
      onSave: async (frequency: RecurrenceFrequency, interval: number) => {
        await dispatch(
          makeBlockTimeRecurrence({
            blockTimeId: blockTime.blockTimeData.id,
            frequency,
            interval,
          }) as any
        ).unwrap();

        const intervalIsGreaterThanOne = interval > 1;

        toast.success(
          `Unavailability is now repeating every ${
            intervalIsGreaterThanOne ? interval : ""
          } day${intervalIsGreaterThanOne ? "s" : ""}`
        );

        getSchedulesOfEmployees({
          currentDate: startOfDay(selectedDate),
        });
      },
    });
  };

  const handleStopRepeating = () => {
    closeDrawer();
    onStopBlockTimeRepeating({
      blockTime,
      onSave: async () => {
        await dispatch(
          stopBlockTimeRecurrence({
            blockTimeRecurrenceId:
              blockTime.blockTimeData.blockTimeRecurrence.id,
            date: selectedDate,
          }) as any
        ).unwrap();

        toast.success("Appointment is no longer repeating");

        getSchedulesOfEmployees({
          currentDate: startOfDay(selectedDate),
        });
      },
    });
  };

  useEffect(() => {
    return () => {
      query.delete("blockTimeId");
      navigate(`${Path.HOME}?${query.toString()}`);
    };
  }, []);

  return {
    onSubmit,
    isDateInputFocused,
    setIsDateInputFocused,
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    duration,
    setDuration,
    setName,
    blockTimeId,
    deleteBlockTime,
    onTimeChange,
    isRepeating,
    handleMakeRepeating,
    handleStopRepeating,
  };
};
