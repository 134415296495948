"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Schedule card — https://app.subframe.com/feb895689ba0/library?component=Schedule+card_504e989d-2556-483f-8a80-4c878d8079a5
 * Avatar — https://app.subframe.com/feb895689ba0/library?component=Avatar_bec25ae6-5010-4485-b46b-cf79e3943ab2
 */

import * as SubframeCore from "@subframe/core";
import { Avatar } from "app/components/avatar/avatar";
import React from "react";

interface ScheduleCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  service?: string;
  time?: string;
  clientName: string;
  clientAvatar: string;
  variant?: "default" | "break";
  locationName?: string;
  className?: string;
  isActive?: boolean
}

const ScheduleCardRoot = React.forwardRef<HTMLElement, ScheduleCardRootProps>(
  function ScheduleCardRoot(
    {
      service,
      time,
      clientName,
      clientAvatar,
      variant = "default",
      className,
      locationName,
      isActive,
      ...otherProps
    }: ScheduleCardRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/504e989d flex w-full items-start",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div
          className={SubframeCore.twClassNames(
            "flex w-1.5 flex-none flex-col rounded-r-none items-start gap-2 self-stretch rounded-2xl  px-1 py-1", isActive ? "bg-success-300" : "bg-blue-300",
            { "bg-neutral-300": variant === "break" }
          )}
        />
        <div
          className={SubframeCore.twClassNames(
            "flex grow shrink-0 basis-0 flex-col items-start gap-3 rounded-r-lg border border-solid px-2 py-4 group-active/504e989d:bg-blue-50",
            isActive ? "border-success-300 bg-[#6CE9A60D]" : "border-blue-300 bg-[#33bfff0f]",
            {
              "border border-solid border-neutral-100 bg-white group-active/504e989d:bg-neutral-50":
                variant === "break",
            }
          )}
        >
          <div className="flex w-full items-start gap-4">
            {service ? (
              <span className="grow shrink-0 basis-0 font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-700">
                {service}
              </span>
            ) : null}
            {time ? (
              <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-700">
                {time}
              </span>
            ) : null}
          </div>
          <div
            className={SubframeCore.twClassNames(
              "flex w-full items-start justify-center gap-2",
              { hidden: variant === "break" }
            )}
          >
            <Avatar
              src={clientAvatar}
              name={clientName}
              type="character"
              size={24}
            />
            {clientName ? (
              <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-700">
                {clientName}
              </span>
            ) : null}
          </div>
          {locationName && (
            <div className="self-end text-neutral-700">{locationName}</div>
          )}
        </div>
      </div>
    );
  }
);

export const ScheduleCard = ScheduleCardRoot;
