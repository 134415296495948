import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface BlockTimeSliceState {
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  blockableType: string;
  blockableId: number;
  appointmentRecurrence?: any;
  account: any;
  shop: any;
  createdAt: string;
  updatedAt: string;
}

export const createBlockTime = createAsyncThunk(
  "blockTime/createBlockTime",
  async (
    {
      name,
      startTime,
      endTime,
      blockableType,
      blockableId,
      blockTimeRecurrenceId,
      shopId,
    }: {
      name: string;
      startTime: string;
      endTime: string;
      blockableType: string;
      blockableId: number;
      blockTimeRecurrenceId?: number;
      shopId: number;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post("/v1/block_times", {
        blockTime: {
          name,
          startTime,
          endTime,
          blockableType,
          blockableId,
          blockTimeRecurrenceId,
          shopId,
        },
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteBlockTime = createAsyncThunk(
  "blockTime/deleteBlockTime",
  async (blockTimeId: number, thunkAPI) => {
    try {
      await http.delete(`/v1/block_times/${blockTimeId}`);
      return blockTimeId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
