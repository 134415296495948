import * as React from "react";
import { durationOptions, useBlockTimeScreen } from "./useBlockTimeScreen";
import { AsInput, reactSelectStyles } from "app/subframe/components/AsInput";
import { formatAsUTC } from "app/utils/formatDate";
import { adjustDateToUTC } from "app/utils/formatTime";
import { InputEdit } from "app/components/inputEdit/InputEdit";
import classNames from "classnames";
import Select from "react-select";
import { find } from "lodash";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { format } from "date-fns";
import { AsButton, AsIconButton } from "app/subframe";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { RecurrenceFrequency } from "app/redux/schedulesSlice";
import { Icon } from "@subframe/core";

export interface BlockTimeScreenProps {
  blockTime: any;
  closeDrawer: () => void;
  getSchedulesOfEmployees: any;
  setSelectedBlockTime: any;
  onMakeBlockTimeRepeating: (data: {
    blockTime: any;
    onSave: (frequency: RecurrenceFrequency, interval: number) => Promise<void>;
  }) => void;
  onStopBlockTimeRepeating: (data: {
    blockTime: any;
    onSave: () => Promise<void>;
  }) => void;
}

export const BlockTimeScreen: React.FC<BlockTimeScreenProps> = ({
  blockTime,
  closeDrawer,
  getSchedulesOfEmployees,
  setSelectedBlockTime,
  onMakeBlockTimeRepeating,
  onStopBlockTimeRepeating,
}) => {
  const {
    onSubmit,
    isDateInputFocused,
    setIsDateInputFocused,
    selectedDate,
    setSelectedDate,
    setSelectedTime,
    duration,
    setDuration,
    setName,
    blockTimeId,
    deleteBlockTime,
    selectedTime,
    onTimeChange,
    isRepeating,
    handleMakeRepeating,
    handleStopRepeating,
  } = useBlockTimeScreen({
    blockTime,
    closeDrawer,
    getSchedulesOfEmployees,
    setSelectedBlockTime,
    onMakeBlockTimeRepeating,
    onStopBlockTimeRepeating,
  });

  return (
    <FadeInSlide direction="left" className="flex flex-col h-full">
      <div className="flex justify-between items-center gap-2 mt-1.5 mb-7">
        <div className="flex items-center justify-between gap-2">
          <p className="text-body-bold font-body-bold text-neutral-900">
            Unavailability
          </p>
          {isRepeating && <Icon name="FeatherRepeat" color="primary" />}
        </div>
        <div className="flex items-center gap-4">
          {blockTimeId && (
            <Dropdown
              classNames={{
                content: "rounded-sm",
              }}
            >
              <DropdownTrigger>
                <AsIconButton
                  variant="ghost"
                  size="sm"
                  leadingIcon={"FeatherMoreHorizontal"}
                />
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  variant="flat"
                  className="rounded-sm"
                  color="default"
                  onClick={() => deleteBlockTime(Number(blockTimeId))}
                >
                  Delete
                </DropdownItem>
                {isRepeating ? (
                  <DropdownItem
                    variant="flat"
                    key="stop-repeating"
                    className="rounded-sm"
                    color="secondary"
                    onClick={handleStopRepeating}
                  >
                    Stop Repeating
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    variant="flat"
                    key="make-repeating"
                    className="rounded-sm"
                    color="primary"
                    onClick={handleMakeRepeating}
                  >
                    Make Repeating
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          )}
          <AsIconButton
            variant="ghost"
            className="rounded p-1 w-6 h-6"
            onClick={() => closeDrawer()}
          >
            <XMarkIcon />
          </AsIconButton>
        </div>
      </div>
      <div className="flex flex-col relative h-[83dvh] mobile:mt-4 overflow-auto">
        <div className="flex items-center gap-2.5 mb-6">
          <AsInput
            aria-label="Date Input"
            key={isDateInputFocused ? "is-focused-date" : "is-not-focused-date"}
            name="date"
            textPosition="left"
            leading="On"
            disabled={!!blockTimeId}
            showDisabledStyles={false}
            defaultValue={
              isDateInputFocused
                ? formatAsUTC(new Date(selectedDate), "yyyy-MM-dd")
                : formatAsUTC(new Date(selectedDate), "EEE, MMM d")
            }
            type={isDateInputFocused ? "date" : "string"}
            containerClassName="w-full"
            onFocus={() => setIsDateInputFocused(true)}
            onChange={(e) => setSelectedDate(adjustDateToUTC(e.target.value))}
            onBlur={(e: any) => {
              const dateValue = e.target.value;
              if (dateValue) {
                const adjustedDate = adjustDateToUTC(dateValue);
                setSelectedDate(adjustedDate.toISOString());
              }
              setIsDateInputFocused(false);
            }}
          />
          <AsInput
            key={blockTime?.start}
            aria-label="Start time Input"
            // defaultValue={blockTime ? selectedTime : "09:00"}
            defaultValue={
              blockTime ? format(blockTime.start, "HH:mm") : "09:00"
            }
            disabled={!!blockTimeId}
            showDisabledStyles={false}
            onChange={(e) => onTimeChange(e.target.value)}
            type="time"
            name="startTime"
            textPosition="left"
            leading={"Start at"}
            containerClassName="w-full"
            className="text-sm text-zinc-500 font-semibold border-zinc-100 bg-transparent"
          />
        </div>

        <div className="flex flex-col gap-6 overflow-auto pb-20 h-[100dvh]">
          <div className="flex flex-col gap-2">
            <p>Reason for unavailability</p>
            <InputEdit
              name="name"
              defaultValue={blockTime.title}
              isEditing={blockTime.title === ""}
              editingClassName="border border-gray-50 px-3 outline-none"
              onSave={({ name }) => setName(name)}
              placeholder="Reason"
              isDisabled={!!blockTimeId}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p>Duration</p>
            {!!blockTimeId ? (
              <p className="py-2">
                {find(durationOptions, { value: duration })?.label}
              </p>
            ) : (
              <Select
                key={`shop-select-${blockTime?.id}`}
                className="w-full py-2"
                options={
                  durationOptions
                    .filter(({ showInList }) => showInList)
                    .map(({ label, value }) => {
                      return {
                        value,
                        label,
                      };
                    }) as any
                }
                isDisabled={!!blockTimeId}
                onChange={({ value }: any) => setDuration(value)}
                placeholder="Select duration"
                defaultValue={find(durationOptions, { value: duration })}
                styles={{
                  ...reactSelectStyles({
                    borderContainer: true,
                  }),
                }}
              />
            )}
          </div>
        </div>
      </div>
      {!blockTimeId && (
        <div className="flex flex-col w-full bottom-0 left-0 fixed bg-white">
          <AsButton
            onClick={() => onSubmit()}
            size="md"
            text="Save and Continue"
            disabled={!duration || blockTime.title === ""}
          />
        </div>
      )}
    </FadeInSlide>
  );
};
