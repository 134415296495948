import * as React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { addDays, subDays, subHours } from "date-fns";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { CrudAppointmentActions, useSchedules } from "./useSchedules";
import { CustomToolbar } from "./components/customToolbar/customToolbar";
import { CustomResourceHeader } from "./components/customResourceHeader";
import { CustomTimeSlotWrapper } from "./components/customTimeSlotWrapper";
import { CustomEvent } from "./components/customEvent/customEvent";
import "./schedules.css";
import { ScheduleDrawer } from "./components/drawer/scheduleDrawer";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { MobileSchedule } from "./mobileSchedule/mobileSchedule";
import classNames from "classnames";
import { useMemo } from "react";
import MakeRepeatAppointment from "./components/repeatAppointmentScreens/makeRepeatAppointment/makeRepeatAppointment";
import StopRepeatAppointment from "./components/repeatAppointmentScreens/stopRepeatAppointment/stopRepeatAppointment";
import EditRepeatAppointment from "./components/repeatAppointmentScreens/editRepeatAppointment/editRepeatAppointment";
import MakeRepeatBlockTime from "./components/repeatBlockTimeScreens/makeRepeatBlockTime/makeRepeatBlockTime";
import StopRepeatingBlockTime from "./components/repeatBlockTimeScreens/stopRepeatBlockTime/stopRepeatBlockTime";
import { AsButton } from "app/subframe";
import { DayPicker } from "react-day-picker";

export interface SchedulesProps {}

const locales = {
  "en-US": enUS,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }), // week starts on Monday
  getDay,
  locales,
  addDays,
  subDays,
});

const DnDCalendar = withDragAndDrop(Calendar);

export const Schedules: React.FC<SchedulesProps> = (props) => {
  const {
    onNavigate,
    date,
    appointments,
    resourceMap,
    onUpdateAppointment,
    onNewSlotSelected,
    pendingAppointment,
    appointmentDrawer,
    setAppointmentDrawer,
    onAppointmentSelect,
    toggleAppointmentDrawer,
    selectedAppointmentEvent,
    setPendingAppointment,
    getSchedulesOfEmployees,
    currentShopTime,
    selectedEmployee,
    setSelectedEmployee,
    createNewPendingAppointment,
    shopId,
    onNewAppointmentInit,
    openMakeRepeatAppointmentModal,
    openStopRepeatAppointmentModal,
    openEditRepeatAppointmentModal,
    closeMakeRepeatAppointmentModal,
    closeStopRepeatAppointmentModal,
    closeEditRepeatAppointmentModal,
    appointmentModalsState,
    setSelectedBlockTime,
    selectedBlockTime,
    blockTimeModalState,
    openMakeRepeatBlockTimeModal,
    openStopRepeatBlockTimeModal,
    closeMakeRepeatBlockTimeModal,
    closeStopRepeatBlockTimeModal,
    showPicker,
    setShowPicker,
    onCustomNavigate,
  } = useSchedules();

  const events = [
    ...appointments,
    ...(pendingAppointment &&
    appointmentDrawer.type !== CrudAppointmentActions.BLOCKED
      ? [pendingAppointment]
      : []),
    ...(selectedBlockTime?.pending &&
    appointmentDrawer.type === CrudAppointmentActions.BLOCKED
      ? [selectedBlockTime]
      : []),
  ];

  const currentShopEvents = useMemo(() => {
    return appointments.filter((app) => app.shops?.includes(shopId));
  }, [appointments, shopId]);

  const { isBelowSm } = useBreakpoint("sm");

  const renderCalendar = () => {
    if (isBelowSm) {
      return (
        <MobileSchedule
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
          appointments={appointments}
          date={date}
          toggleAppointmentDrawer={toggleAppointmentDrawer}
          onAppointmentSelect={onAppointmentSelect}
          createNewPendingAppointment={createNewPendingAppointment}
          currentShopTime={currentShopTime}
        />
      );
    } else {
      return (
        <>
          <DnDCalendar
            date={date}
            getNow={() => currentShopTime}
            scrollToTime={subHours(currentShopTime, 2)}
            defaultView={Views.DAY}
            localizer={localizer}
            events={events} // add pending appointments
            draggableAccessor={(event) => true}
            resources={resourceMap}
            onEventDrop={onUpdateAppointment}
            onNavigate={onNavigate}
            resizable
            onSelectEvent={onAppointmentSelect}
            resourceIdAccessor={(event: any) => event.resourceId}
            resourceTitleAccessor={(resource: any) => resource.resourceTitle}
            selectable
            onSelectSlot={onNewSlotSelected}
            showMultiDayTimes
            dayLayoutAlgorithm={"no-overlap"}
            step={10}
            timeslots={6}
            className="relative"
            onDragOver={(e) => console.log(e)}
            components={{
              toolbar: (props) => (
                <CustomToolbar
                  showPicker={showPicker}
                  setShowPicker={setShowPicker}
                  onCustomNavigate={onCustomNavigate}
                  {...props}
                />
              ),
              resourceHeader: CustomResourceHeader,
              timeSlotWrapper: CustomTimeSlotWrapper,
              event: (props) => <CustomEvent {...props} />,
            }}
          />
          {/* <AsButton
            onClick={(event) => onAppointmentSelect(event)}
            className="absolute bottom-4 left-1/2"
            text="Add Appointment"
            variant="brand"
          /> */}
        </>
      );
    }
  };

  return (
    <div
      className={classNames({
        "h-[85dvh]": isBelowSm && appointmentDrawer.isOpen,
      })}
    >
      <ScheduleDrawer
        appointmentDrawer={appointmentDrawer}
        toggleAppointmentDrawer={toggleAppointmentDrawer}
        setAppointmentDrawer={setAppointmentDrawer}
        selectedAppointmentEvent={selectedAppointmentEvent}
        getSchedulesOfEmployees={getSchedulesOfEmployees}
        setPendingAppointment={setPendingAppointment}
        pendingAppointment={pendingAppointment}
        onMakeAppointmentRepeating={openMakeRepeatAppointmentModal}
        onStopAppointmentRepeating={openStopRepeatAppointmentModal}
        onEditAppointmentRepeating={openEditRepeatAppointmentModal}
        onMakeBlockTimeRepeating={openMakeRepeatBlockTimeModal}
        onStopBlockTimeRepeating={openStopRepeatBlockTimeModal}
        selectedBlockTime={selectedBlockTime}
        setSelectedBlockTime={setSelectedBlockTime}
      />
      {renderCalendar()}

      <MakeRepeatAppointment
        isOpen={appointmentModalsState.makeRepeatAppointment.open}
        onOpenChange={(isOpen) => {
          if (isOpen) return;

          closeMakeRepeatAppointmentModal();
        }}
        appointmentGroup={
          appointmentModalsState.makeRepeatAppointment.appointmentGroup
        }
        onSave={appointmentModalsState.makeRepeatAppointment.onSave}
      />
      <StopRepeatAppointment
        isOpen={appointmentModalsState.stopRepeatAppointment.open}
        onOpenChange={(isOpen) => {
          if (isOpen) return;

          closeStopRepeatAppointmentModal();
        }}
        appointmentGroup={
          appointmentModalsState.stopRepeatAppointment.appointmentGroup
        }
        onSave={appointmentModalsState.stopRepeatAppointment.onSave}
      />
      <EditRepeatAppointment
        isOpen={appointmentModalsState.editRepeatAppointment.open}
        onOpenChange={(isOpen) => {
          if (isOpen) return;

          closeEditRepeatAppointmentModal();
        }}
        toggleAppointmentDrawer={toggleAppointmentDrawer}
        selectedServices={
          appointmentModalsState.editRepeatAppointment.selectedServices
        }
        selectedClient={
          appointmentModalsState.editRepeatAppointment.selectedClient
        }
        parsedDate={appointmentModalsState.editRepeatAppointment.parsedDate}
        parsedTime={appointmentModalsState.editRepeatAppointment.parsedTime}
        onUpdateThisAppointmentOnly={
          appointmentModalsState.editRepeatAppointment
            .onUpdateThisAppointmentOnly
        }
        onUpdateAllFutureAppointments={
          appointmentModalsState.editRepeatAppointment
            .onUpdateAllFutureAppointments
        }
      />
      <MakeRepeatBlockTime
        isOpen={blockTimeModalState.makeRepeatBlockTime.open}
        onOpenChange={(isOpen) => {
          if (isOpen) return;

          closeMakeRepeatBlockTimeModal();
        }}
        blockTime={blockTimeModalState.makeRepeatBlockTime.blockTime}
        onSave={blockTimeModalState.makeRepeatBlockTime.onSave}
      />
      <StopRepeatingBlockTime
        isOpen={blockTimeModalState.stopRepeatBlockTime.open}
        onOpenChange={(isOpen) => {
          if (isOpen) return;

          closeStopRepeatBlockTimeModal();
        }}
        blockTime={blockTimeModalState.stopRepeatBlockTime.blockTime}
        onSave={blockTimeModalState.stopRepeatBlockTime.onSave}
      />
    </div>
  );
};
