"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Mobile CRM card — https://app.subframe.com/feb895689ba0/library?component=Mobile+CRM+card_6407a3a7-e975-4dd6-85cf-6553358b8417
 * Avatar — https://app.subframe.com/feb895689ba0/library?component=Avatar_bec25ae6-5010-4485-b46b-cf79e3943ab2
 * AsBadge — https://app.subframe.com/feb895689ba0/library?component=AsBadge_a23f3ea7-3649-4c1b-9f5a-e2094098f065
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { Avatar } from "app/components/avatar/avatar";
import { AsBadge } from "./AsBadge";

interface MobileCrmCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  email?: string;
  className?: string;
  avatarImg?: string;
}

const MobileCrmCardRoot = React.forwardRef<HTMLElement, MobileCrmCardRootProps>(
  function MobileCrmCardRoot(
    {
      name,
      avatarImg,
      email,
      className,
      ...otherProps
    }: MobileCrmCardRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/6407a3a7 flex w-full items-center gap-2 overflow-hidden rounded-lg border border-solid border-neutral-50 bg-white px-2 py-4 active:border active:border-solid active:border-neutral-500 active:bg-millet-300",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <Avatar size={56} src={avatarImg!} name={name!} type="character" />
        <div className="flex grow shrink-0 basis-0 flex-col items-start justify-center gap-2">
          <div className="flex w-full items-center gap-2">
            {name ? (
              <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-700">
                {name}
              </span>
            ) : null}
            <AsBadge
              className="hidden"
              variant="neutral"
              icon={null}
              iconRight={null}
            >
              Closed
            </AsBadge>
            <AsBadge
              className="hidden"
              variant="void"
              icon={null}
              iconRight={null}
            >
              Void
            </AsBadge>
          </div>
          <div className="flex w-full items-center gap-2">
            {email ? (
              <span className="grow shrink-0 basis-0 font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500">
                {email}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
);

export const MobileCrmCard = MobileCrmCardRoot;
