import classNames from "classnames";
import { format } from "date-fns";
import * as React from "react";
import { useCustomEvent } from "./useCustomEvent";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { truncate } from "lodash";

export const CustomEvent: React.FC<any> = ({
  event: { client, employee, shop, start, end, pending, type, appointment },
  title,
}) => {
  const {
    isOffTime,
    isCurrentShop,
    isABlockedTime,
    isAnActiveTime,
    isOverlappingOther,
    isOverlappingAbove,
  } = useCustomEvent({
    title,
    shop,
    type,
    start,
    end,
    employee,
    appointment,
  });

  const isANonAppointment = isOffTime || isABlockedTime;

  if (isOffTime) {
    console.log("start", start);
    console.log("end", end);
    console.log("appointment", appointment);
  }

  return (
    <div
      className={classNames(
        "absolute top-0 left-0 w-full h-full overflow-hidden flex",
        {
          "border-success-300 bg-[#6CE9A60D]":
            !isANonAppointment && isCurrentShop && isAnActiveTime,
          "border-blue-300 bg-[#33bfff0f]":
            !isANonAppointment && isCurrentShop && !isAnActiveTime,
          "bg-[#F4F4F5] cursor-default mx-0 !w-[calc(100%+2.3rem)] -ml-5":
            isOffTime,
          "border-neutral-400 bg-[#F4F4F5]": !isOffTime && !isCurrentShop,
          "border-neutral-400 bg-[#A1A1AA1A]": pending,
          "border-neutral-400 bg-[#A1A1AA1A] cursor-pointer border-1 rounded-lg":
            isABlockedTime,
          "border-1 rounded-lg": !isANonAppointment,

          // Overlap styles
          overlapping:
            (isOverlappingOther && !isOverlappingAbove) ||
            (isABlockedTime && !isOverlappingAbove) ||
            isOffTime,
          "bg-[#F6FBFF] !shadow-lg": isOverlappingOther && isOverlappingAbove,
        }
      )}
    >
      <div
        className={classNames("w-1.5 h-full", {
          "bg-success-300":
            !isANonAppointment && isCurrentShop && isAnActiveTime,
          "bg-blue-300": !isANonAppointment && isCurrentShop && !isAnActiveTime,
          "display-none cursor-default": isOffTime,
          "bg-neutral-400": (!isOffTime && !isCurrentShop) || pending,
          "bg-neutral-400 cursor-pointer": isABlockedTime,
        })}
      />
      <div className="p-1.5 flex-1">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between items-center">
            <p className="text-zinc-700 text-sm">
              {!isOffTime &&
                (!isOffTime && !isCurrentShop && !pending ? (
                  isABlockedTime ? (
                    <span className="">{truncate(title, { length: 25 })}</span>
                  ) : (
                    `@ ${shop?.name}`
                  )
                ) : (
                  <span className="">{truncate(title, { length: 25 })}</span>
                ))}
            </p>

            <div className="flex items-center gap-1">
              {!isOffTime && !isCurrentShop && !pending && !isABlockedTime && (
                <MapPinIcon className="h-4 w-4 text-zinc-700" />
              )}
              <p className="text-zinc-700 text-sm">
                {/* {!isOffTime && !isOverlappingAbove && format(start, "h:mm a")} */}
                {!isOffTime &&
                  !isOverlappingAbove &&
                  isCurrentShop &&
                  `${format(start, "h:mm")} ${format(start, "a")
                    .toLowerCase()
                    .charAt(0)}`}
              </p>
            </div>
          </div>
          {client?.name && (
            <p className="text-zinc-700 text-sm font-semibold">{client.name}</p>
          )}
        </div>
      </div>
    </div>
  );
};
