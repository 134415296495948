import {
  archiveBusinessService,
  archiveService,
  selectBusinessServices,
  updateBusinessService,
  updateBusinessServiceCategory,
} from "app/redux/businessServicesSlice";
import { useQuery } from "app/utils/useQuery";
import { find, set } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { durationList } from "app/redux/businessServicesSlice";
import { toast } from "react-toastify";

export const useBusinessServices = () => {
  const dispatch = useDispatch();
  const businessServices = useSelector(selectBusinessServices);
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();
  const selectedServiceParams = {
    service: query.get("service"),
    category: query.get("category"),
    categoryId: query.get("categoryId"),
  };
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState<any>();
  const [duration, setDuration] = useState<any>({});
  const [price, setPrice] = useState<string | undefined>(undefined);
  const [originalDuration, setOriginalDuration] = useState<any>({});
  const [originalPrice, setOriginalPrice] = useState<string | undefined>(
    undefined
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);
  const [serviceCategoryIdForNewService, setServiceCategoryIdForNewService] =
    useState<number>();
  const [openCategoryId, setOpenCategoryId] = React.useState<number | null>(
    Number(query.get("categoryId")) || businessServices[0]?.id
  );

  const getSelectedService = (id: number) => {
    return businessServices
      .map((category) => category.services)
      .flat()
      .find((service) => service.id === id);
  };

  const onUpdateService = async (serviceId: any) => {
    const payload = {
      id: serviceId,
      duration: duration.duration,
      priceCents: Number(price) * 100,
    };

    try {
      setIsUpdating(true);
      await dispatch(updateBusinessService(payload) as any).unwrap();
      toast.success("Service updated successfully");
      setIsUpdating(false);
    } catch (error: any) {
      setIsUpdating(false);
      console.error(error);
    }
  };

  const onArchiveServiceCategory = async (categoryId: number) => {
    const shouldArchive = window.confirm(
      `Are you sure you want to delete all services in the ${selectedServiceParams.category} category?`
    );

    if (!shouldArchive) {
      return;
    }

    try {
      await dispatch(
        archiveBusinessService({ id: categoryId }) as any
      ).unwrap();
      toast.success("Service category archived successfully");
    } catch (error: any) {
      console.error(error);
    }
  };

  const onArchiveService = async (serviceId: number) => {
    const shouldArchive = window.confirm(
      `Are you sure you want to delete this service?`
    );

    if (!shouldArchive) {
      return;
    }

    try {
      await dispatch(archiveService({ id: serviceId }) as any).unwrap();
      toast.success("Service category archived successfully");
    } catch (error: any) {
      console.error(error);
    }
  };

  const onServicePress = ({
    serviceId,
    categoryName,
    categoryId,
  }: {
    serviceId: number;
    categoryName: string;
    categoryId: number;
  }) => {
    navigate(
      `?service=${serviceId}&category=${categoryName}&categoryId=${categoryId}`
    );
  };

  const onServiceNameSave = async (data: any) => {
    try {
      await dispatch(
        updateBusinessService({
          id: selectedServiceParams.service,
          ...data,
        }) as any
      ).unwrap();
      toast.success("Service name updated successfully");
    } catch (error: any) {
      console.error(error);
    }
  };

  const onServiceCategoryNameSave = async (data: any) => {
    try {
      await dispatch(
        updateBusinessServiceCategory({
          id: selectedServiceParams.categoryId,
          ...data,
        }) as any
      ).unwrap();
      navigate(
        `?service=${selectedServiceParams.service}&category=${data.name}&categoryId=${selectedServiceParams.categoryId}`
      );
      toast.success("Service category name updated successfully");
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    const service = getSelectedService(Number(selectedServiceParams.service));
    setSelectedService(service);
    if (service) {
      const duration = find(durationList, { duration: service.duration });
      setDuration(duration);
      setPrice(String(service.priceCents / 100));
      // Set original values
      setOriginalDuration(duration);
      setOriginalPrice(String(service.priceCents / 100));
    }
  }, [
    selectedServiceParams.service,
    selectedServiceParams.category,
    businessServices,
  ]);

  const isDirty = () =>
    duration.duration !== originalDuration.duration || price !== originalPrice;

  const onAddServiceClick = (categoryId: number) => {
    setShowAddServiceModal(true);
    setServiceCategoryIdForNewService(categoryId);
  };

  useEffect(() => {
    if (businessServices.length === 0 || !!query.get("service")) {
      return;
    }
    const intialBusinessService = businessServices[0];
    onServicePress({
      categoryId: intialBusinessService.id,
      categoryName: intialBusinessService.name,
      serviceId: intialBusinessService.services[0].id,
    });
  }, []);

  return {
    isLoading,
    businessServices,
    onServicePress,
    selectedServiceParams,
    getSelectedService,
    onServiceNameSave,
    selectedService,
    duration,
    setDuration,
    price,
    setPrice,
    onUpdateService,
    isDirty,
    isUpdating,
    onServiceCategoryNameSave,
    showAddCategoryModal,
    setShowAddCategoryModal,
    showAddServiceModal,
    setShowAddServiceModal,
    onAddServiceClick,
    serviceCategoryIdForNewService,
    openCategoryId,
    setOpenCategoryId,
    onArchiveServiceCategory,
    onArchiveService,
  };
};
