import { SectionHeader } from "app/components/sectionHeader";
import * as React from "react";
import { SelectItem, Select, Input, Spinner, Button } from "@nextui-org/react";
import { useStaffServices } from "./useStaffServices";
import { durationList } from "app/redux/businessServicesSlice";
import { EmployeesSliceState } from "app/redux/employeesSlice";
import { Divider, Switch } from "@nextui-org/react";
import classNames from "classnames";
import { find } from "lodash";

export interface StaffServicesProps {
  selectedEmployee: EmployeesSliceState;
}

export const StaffServices: React.FC<StaffServicesProps> = ({
  selectedEmployee,
}) => {
  const {
    combinedCategories,
    durations,
    prices,
    setDuration,
    setPrice,
    dirtyServices,
    updatingServices,
    updateService,
    handleActiveToggle,
    activeStatus,
  } = useStaffServices({ selectedEmployee });

  return (
    <div className="flex flex-col gap-6">
      <SectionHeader title="Services" />
      {combinedCategories.map((category) => (
        <div
          key={category.id}
          className="flex flex-col p-4 border rounded border-neutral-50"
        >
          <p className="text-zinc-700 font-semibold">{category.name}</p>
          <Divider className="my-4" />
          <div className="flex flex-col gap-4">
            {category.services.map((service: any) => (
              <div
                key={service.id}
                className={classNames(
                  "flex flex-col justify-between items-center border p-4 rounded",
                  {
                    "border-neutral-50": !activeStatus[service.id],
                    "border-[#a2e9c1]": activeStatus[service.id],
                  }
                )}
              >
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Switch
                      color="success"
                      size="sm"
                      defaultSelected={service.active}
                      onChange={(event) =>
                        handleActiveToggle(service.id, event.target.checked)
                      }
                      aria-label="Service status"
                    />
                    <p className="text-zinc-700 font-semibold">
                      {service.title}
                    </p>
                  </div>
                  <Button
                    onClick={() => updateService(service.id)}
                    variant="light"
                    isDisabled={
                      !dirtyServices[service.id] || updatingServices[service.id]
                    } // Disable if not dirty or updating
                    className="py-2 px-1 rounded h-fit"
                  >
                    {updatingServices[service.id] && <Spinner size="sm" />}
                    <p className="text-body-bold font-body-bold text-neutral-900">
                      Update
                    </p>
                  </Button>
                </div>
                <div
                  className={classNames(
                    "flex flex-col w-full gap-4 overflow-hidden transform transition-all duration-500",
                    {
                      "max-h-0 mt-0 hidden": !activeStatus[service.id],
                      "max-h-[1000px] mt-2": activeStatus[service.id], // Adjust max-height based on expected content size
                    }
                  )}
                >
                  <div className="flex w-full justify-between items-center">
                    <p className="text-body-bold font-body-bold text-neutral-900">
                      Duration
                    </p>
                    <Select
                      variant="bordered"
                      aria-label="Duration"
                      className="max-w-[120px] bg-transparent"
                      classNames={{
                        trigger: "border-[#F4F4F5] rounded shadow-sm border",
                      }}
                      value={String(durations[service.id])} // Ensure value is valid
                      selectedKeys={[String(durations[service.id] || "")]} // Ensure selectedKeys is valid
                      onChange={(event) => {
                        const durationValue = Number(event.target.value);
                        setDuration({
                          id: service.id,
                          duration: durationValue,
                          label: find(durationList, {
                            duration: durationValue,
                          })?.label,
                        });
                      }}
                    >
                      {durationList.map(({ duration, label }) => (
                        <SelectItem key={String(duration)} value={duration}>
                          {label}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                  <div className="flex w-full justify-between items-center">
                    <p className="text-body-bold font-body-bold text-neutral-900">
                      Price
                    </p>
                    <Input
                      variant="bordered"
                      startContent="$"
                      type="number"
                      classNames={{
                        base: "w-full max-w-[120px]",
                        inputWrapper:
                          "border-[#F4F4F5] rounded shadow-sm border",
                        input: "font-semibold",
                      }}
                      value={prices[service.id] || ""}
                      onChange={(e) =>
                        setPrice({
                          id: service.id,
                          price: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
