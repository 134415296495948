import { Path } from "app/path";
import {
  createSale,
  getSale,
  SaleStatusTypes,
  selectSales,
  updateSale,
  voidSale,
} from "app/redux/salesSlice";
import {
  CheckoutScreenTypes,
  DualPricingPaymentTypes,
} from "app/sales/useSales";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useClosedSaleScreen = ({
  setShouldShowDrawer,
}: {
  setShouldShowDrawer: (open: boolean) => void;
}) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sales } = useSelector(selectSales);
  const saleParam = query.get("saleId");
  const [sale, setSale] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [loadingReason, setLoadingReason] = useState("");

  const onCloseDrawer = () => {
    query.delete("saleId");
    query.delete("status");
    query.delete("screen");
    navigate(`${Path.SALES}?${query.toString()}`);
    setShouldShowDrawer(false);
  };

  const onPostPaymentRecord = (postSale: any) => {
    setSale(postSale);
    query.delete("status");
    query.delete("screen");
    query.delete("dualPricingPaymentMethod");
    if (postSale.status === SaleStatusTypes.OPEN) {
      query.delete("saleId");
      query.set("saleId", postSale.id.toString());
      query.set("screen", CheckoutScreenTypes.INITIAL);
      query.set("status", "open");
    } else if (postSale.status === SaleStatusTypes.VOID) {
      query.set("screen", CheckoutScreenTypes.CLOSED);
      query.set("status", CheckoutScreenTypes.VOID);
    }
    navigate(`${Path.SALES}?${query.toString()}`);
  };

  const onVoidSale = async () => {
    try {
      setLoading(true);
      setLoadingReason("Voiding sale");
      const newSale = await dispatch(
        voidSale({ id: Number(sale.id) }) as any
      ).unwrap();
      setLoading(false);
      onPostPaymentRecord(newSale);
      setLoadingReason("");
      toast.success("Sale voided successfully");
    } catch (error: any) {
      setLoading(false);
      setLoadingReason("");
      if (error.error?.error_message) {
        return toast.error(error.error.error_message);
      } else {
        toast.error("Failed to void sale");
      }
      console.error("Failed to void sale");
    }
  };

  const onCloneSale = async (sale: any) => {
    try {
      setLoading(true);
      setLoadingReason("Cloning sale");
      const newSale = await dispatch(
        createSale({
          ...sale,
          status: SaleStatusTypes.OPEN,
          acceptBlueTransactionId: null,
          merchantServiceIds: sale.salesMerchantServices.map(
            (sms: any) => sms.merchantService.id
          ),
          clientId: sale.client.id,
          createdById: sale.createdBy.id,
        }) as any
      ).unwrap();
      onPostPaymentRecord(newSale);
      setLoading(false);
      setLoadingReason("");
      toast.success("Sale cloned successfully");
    } catch (error) {
      console.error("Failed to clone sale:", error);
      setLoading(false);
      setLoadingReason("");
    }
  };

  const onOpenSale = async () => {
    try {
      setLoading(true);
      setLoadingReason("Opening sale");
      const newSale = await dispatch(
        updateSale({
          ...sale,
          status: SaleStatusTypes.OPEN,
        }) as any
      ).unwrap();
      onPostPaymentRecord(newSale);
      toast.success(`Reopened Sale ${newSale.id}`);
      setLoading(false);
      setLoadingReason("");
    } catch (error) {
      console.error("Failed to open sale:", error);
      setLoading(false);
      setLoadingReason("");
    }
  };

  useEffect(() => {
    const fetchSale = async () => {
      setLoading(true);
      let selectedSale = find(sales, (s) => s.id === Number(saleParam)) || null;
      if (!selectedSale) {
        try {
          const fetchedSale = await dispatch(
            getSale({
              id: Number(saleParam),
            }) as any
          ).unwrap();
          selectedSale = fetchedSale;
        } catch (error) {
          console.error("Failed to fetch sale:", error);
        }
      }

      setSale(selectedSale);
      setLoading(false);
    };

    fetchSale();
  }, [saleParam, sales, dispatch]);

  return {
    sale,
    loading,
    onVoidSale,
    loadingReason,
    onCloneSale,
    onOpenSale,
    onCloseDrawer,
  };
};
