import { Drawer } from "app/components/drawer/drawer";
import { CrudAppointmentActions } from "app/schedules/useSchedules";
import * as React from "react";
import { ReadAppointmentScreen } from "app/schedules/components/crudAppointmentScreens/readAppointmentScreen/readAppointmentScreen";
import { CreateAppointmentScreen } from "app/schedules/components/crudAppointmentScreens/createAppointmentScreen/createAppointmentScreen";
import { RecurrenceFrequency } from "app/redux/schedulesSlice";
import { BlockTimeScreen } from "../blockTimeScreen/blockTimeScreen";
import { includes } from "lodash";

export interface ScheduleDrawerProps {
  appointmentDrawer: any;
  toggleAppointmentDrawer: any;
  setAppointmentDrawer: any;
  selectedAppointmentEvent: any;
  getSchedulesOfEmployees: any;
  setPendingAppointment: any;
  pendingAppointment: any;
  onMakeAppointmentRepeating: (data: {
    appointmentGroup: any;
    onSave: (frequency: RecurrenceFrequency, interval: number) => Promise<void>;
  }) => void;
  onStopAppointmentRepeating: (data: {
    appointmentGroup: any;
    onSave: () => Promise<void>;
  }) => void;
  onEditAppointmentRepeating: (data: {
    selectedClient: any;
    selectedServices: any[];
    parsedDate: Date;
    parsedTime: Date;
    onUpdateThisAppointmentOnly: () => Promise<void>;
    onUpdateAllFutureAppointments: () => Promise<void>;
  }) => void;
  selectedBlockTime?: any;
  setSelectedBlockTime?: any;
  onMakeBlockTimeRepeating: (data: {
    blockTime: any;
    onSave: (frequency: RecurrenceFrequency, interval: number) => Promise<void>;
  }) => void;
  onStopBlockTimeRepeating: (data: {
    blockTime: any;
    onSave: () => Promise<void>;
  }) => void;
}

export const ScheduleDrawer: React.FC<ScheduleDrawerProps> = ({
  appointmentDrawer,
  toggleAppointmentDrawer,
  setAppointmentDrawer,
  selectedAppointmentEvent,
  getSchedulesOfEmployees,
  setPendingAppointment,
  pendingAppointment,
  onMakeAppointmentRepeating,
  onStopAppointmentRepeating,
  onEditAppointmentRepeating,
  selectedBlockTime,
  setSelectedBlockTime,
  onMakeBlockTimeRepeating,
  onStopBlockTimeRepeating,
}) => {
  return (
    <Drawer
      isOpen={appointmentDrawer.isOpen}
      setIsOpen={toggleAppointmentDrawer}
      backdrop={false}
      hideCloseBtn={includes(
        [
          CrudAppointmentActions.READ,
          CrudAppointmentActions.CREATE,
          CrudAppointmentActions.BLOCKED,
        ],
        appointmentDrawer.type
      )}
      // hideCloseBtn
      onClose={() => setAppointmentDrawer({ isOpen: false, type: null })}
    >
      {appointmentDrawer.type === CrudAppointmentActions.READ && (
        <ReadAppointmentScreen
          selectedAppointmentEvent={selectedAppointmentEvent}
          getSchedulesOfEmployees={getSchedulesOfEmployees}
          toggleAppointmentDrawer={toggleAppointmentDrawer}
          setPendingAppointment={setPendingAppointment}
          closeDrawer={() =>
            setAppointmentDrawer({ isOpen: false, type: null })
          }
          onMakeAppointmentRepeating={onMakeAppointmentRepeating}
          onStopAppointmentRepeating={onStopAppointmentRepeating}
          onEditAppointmentRepeating={onEditAppointmentRepeating}
        />
      )}
      {appointmentDrawer.type === CrudAppointmentActions.CREATE && (
        <CreateAppointmentScreen
          pendingAppointment={pendingAppointment}
          setPendingAppointment={setPendingAppointment}
          getSchedulesOfEmployees={getSchedulesOfEmployees}
          toggleAppointmentDrawer={toggleAppointmentDrawer}
          setAppointmentDrawer={setAppointmentDrawer}
          closeDrawer={() =>
            setAppointmentDrawer({ isOpen: false, type: null })
          }
        />
      )}
      {appointmentDrawer.type === CrudAppointmentActions.BLOCKED && (
        <BlockTimeScreen
          blockTime={selectedBlockTime}
          closeDrawer={() =>
            setAppointmentDrawer({ isOpen: false, type: null })
          }
          getSchedulesOfEmployees={getSchedulesOfEmployees}
          setSelectedBlockTime={setSelectedBlockTime}
          onMakeBlockTimeRepeating={onMakeBlockTimeRepeating}
          onStopBlockTimeRepeating={onStopBlockTimeRepeating}
        />
      )}
    </Drawer>
  );
};
