"use client";

import * as React from "react";
import { DialogLayout } from "app/subframe/layouts/DialogLayout";
import { AsIconButton } from "app/subframe/components/AsIconButton";
import { Avatar } from "app/components/avatar/avatar";
import { AsButton } from "app/subframe/components/AsButton";
import useEditRepeatAppointment from "./useEditRepeatAppointment";
import { CrudAppointmentActions } from "app/schedules/useSchedules";
import { format } from "date-fns";

interface EditRepeatAppointmentProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  toggleAppointmentDrawer: (
    isOpen: boolean,
    action?: CrudAppointmentActions
  ) => void;
  selectedClient: any;
  selectedServices: any[];
  parsedDate: Date;
  parsedTime: Date;
  onUpdateThisAppointmentOnly?: () => void;
  onUpdateAllFutureAppointments?: () => void;
}

function EditRepeatAppointment({
  isOpen,
  selectedClient,
  selectedServices,
  parsedDate,
  parsedTime,
  onOpenChange,
  toggleAppointmentDrawer,
  onUpdateThisAppointmentOnly,
  onUpdateAllFutureAppointments,
}: EditRepeatAppointmentProps) {
  const { handleThisAppointmentOnly, handleAllFutureAppointments } =
    useEditRepeatAppointment({
      onOpenChange,
      toggleAppointmentDrawer,
      onUpdateThisAppointmentOnly,
      onUpdateAllFutureAppointments,
    });

  // const { selectedClient, date, time, selectedServices } = appointmentDetails;

  return (
    <DialogLayout
      open={isOpen}
      onOpenChange={onOpenChange}
      className="z-[1000]"
    >
      <div className="flex h-144 w-192 flex-col items-center gap-4 rounded-lg bg-default-background px-6 pt-6 pb-10 shadow-dialog-shadow mobile:h-144 mobile:w-96 mobile:px-2 mobile:pt-2 mobile:pb-4">
        <div className="flex w-full items-start justify-end gap-6">
          <div className="flex grow shrink-0 basis-0 flex-col items-start justify-center gap-2">
            <span className="text-body-bold font-body-bold text-neutral-900">
              Update repeating appointment
            </span>
            <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-700">
              Select your update option.
            </span>
          </div>
          <AsIconButton
            className="h-8 w-8 flex-none"
            variant="ghost"
            leadingIcon="FeatherX"
            trailingIcon={null}
            text="Label"
            size="default"
            disabled={false}
            disabledSecondary={false}
            onClick={() => {
              onOpenChange(false);
            }}
          />
        </div>
        <div className="flex w-full flex-col items-center gap-4 rounded-md border border-solid border-neutral-50 bg-neutral-0 px-3 py-4">
          <div className="flex w-full items-center gap-2">
            <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-700">
              Edit to this appointment
            </span>
          </div>
          <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
          <div className="flex w-full items-start gap-4">
            <Avatar
              src={selectedClient?.avatar}
              name={selectedClient?.name}
              size={40}
              type="character"
            />
            <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2">
              <div className="flex flex-col items-start justify-center gap-2">
                <span className="text-body-bold font-body-bold text-neutral-700">
                  {selectedClient?.name}
                </span>
              </div>
              <div className="flex items-start gap-2">
                <div className="flex items-center gap-1">
                  <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500">
                    on
                  </span>
                  <span className="font-['Inter'] text-[14px] font-[600] leading-[20px] text-neutral-700">
                    {format(parsedDate, "EEE, MMM yyyy")}
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500">
                    at
                  </span>
                  <span className="font-['Inter'] text-[14px] font-[600] leading-[20px] text-neutral-700">
                    {format(parsedTime, "h:mm a")}
                  </span>
                </div>
              </div>
              {selectedServices.map((service: any, index: number) => (
                <div className="flex items-start gap-2 flex-wrap" key={index}>
                  <span className="font-['Inter'] font-[600] leading-[24px] text-neutral-700">
                    {service.title}
                  </span>
                  <div className="flex items-center gap-1">
                    <span className="font-['Inter'] font-[400] leading-[24px] text-neutral-500">
                      with
                    </span>
                    <span className="font-['Inter'] font-[600] leading-[24px] text-neutral-700">
                      {service?.employee?.name}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-10" />
        <div className="flex w-full max-w-[468px] grow shrink-0 basis-0 items-end justify-center gap-4">
          <AsButton
            className="w-full"
            variant="secondary"
            leadingIcon={null}
            trailingIcon={null}
            text="This appointment only"
            size="md"
            loader={false}
            disabled={false}
            disabledSecondary={false}
            shadow={false}
            onClick={handleThisAppointmentOnly}
          />
          <AsButton
            className="w-full"
            variant="secondary"
            leadingIcon={null}
            trailingIcon={null}
            text="All future appointments"
            size="md"
            loader={false}
            disabled={false}
            disabledSecondary={false}
            shadow={false}
            onClick={handleAllFutureAppointments}
          />
        </div>
      </div>
    </DialogLayout>
  );
}

export default EditRepeatAppointment;
