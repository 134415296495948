import * as React from "react";
import { useEffect, useRef } from "react";
import classNames from "classnames";
import PhoneInput from "react-phone-input-2";
import { formatPhoneNumber } from "app/utils/formatPhone";
import { truncate } from "lodash";
import { AsIconButton } from "app/subframe";
import { format24TimeTo12Hour } from "app/utils/formatTime";

export interface InputEditProps {
  isEditing?: boolean;
  name: string;
  defaultValue?: string;
  onSave?: (value: any) => void;
  onCancel?: () => void;
  valueClassName?: string;
  type?: string;
  label?: string;
  valueShownWhenNotEditing?: any;
  isDisabled?: boolean;
  placeholder?: string;
  truncateLength?: number;
  editingClassName?: string;
  containerClassName?: string;
}

export const InputEdit: React.FC<InputEditProps> = ({
  name,
  defaultValue,
  // isEditing = !defaultValue ? true : false,
  isEditing = false,
  onSave,
  valueClassName = "",
  type = "text",
  label,
  valueShownWhenNotEditing,
  placeholder,
  isDisabled = false,
  truncateLength,
  editingClassName,
  containerClassName,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [editing, setEditing] = React.useState(isEditing);
  const [value, setValue] = React.useState<any>(defaultValue);
  const [displayValue, setDisplayValue] = React.useState(
    valueShownWhenNotEditing
  );

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (onSave) {
      onSave({ [name]: value });
    }
    handleAfterSave();
    setEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSave(e as any);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      if (onSave) {
        onSave({ [name]: inputRef.current?.value });
        setValue(inputRef.current?.value);
      }
      handleAfterSave(inputRef.current?.value);
      setEditing(false);
    }
  };

  const handleAfterSave = (passedValue?: any) => {
    if (type === "time") {
      setDisplayValue(format24TimeTo12Hour(passedValue || value));
    } else {
      setDisplayValue(passedValue || value);
    }
  };

  useEffect(() => {
    if (editing) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editing]);

  useEffect(() => {
    const defaultValueToSet = truncateLength
      ? truncate(defaultValue, { length: truncateLength, omission: ".." })
      : defaultValue;

    setValue(defaultValueToSet || "");
  }, [defaultValue]);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
      if (type === "time") {
        inputRef?.current?.showPicker(); // Opens the time picker (if supported)
      }
      // Only call setSelectionRange for input types that support it
      if (["text", "search", "password", "tel", "url"].includes(type)) {
        // Only call setSelectionRange for supported input types
        inputRef?.current?.setSelectionRange(0, value?.length);
      }
    }
  }, [editing]);

  return editing ? (
    <div ref={containerRef} className="flex flex-col w-full">
      {label && <p className="text-zinc-500 text-sm">{label}</p>}
      <form
        onSubmit={handleSave}
        className="flex items-center w-full justify-between"
      >
        {type === "phone" ? (
          <PhoneInput
            value={value}
            specialLabel=""
            onKeyDown={handleKeyDown}
            inputProps={{ name: name, ref: inputRef }}
            onChange={(phone) => setValue(phone)}
            placeholder="+1 (555) 555-5555"
            containerClass="w-full"
            inputClass={`focus:outline-none w-full py-2 pl-2 ${valueClassName}`}
          />
        ) : (
          <input
            // variant="bordered"
            // classNames={{
            //   inputWrapper:
            //     "bg-transparent border-[#F4F4F5] rounded shadow-sm border",
            // }}
            // label={label}
            placeholder={placeholder}
            name={name}
            ref={inputRef}
            // onSelect={(e) => e.preventDefault()}
            onSelect={(e: any) => {
              if (type === "time") {
                setValue(e.target.value);
                setDisplayValue(e.target.value);
              }
            }}
            className={classNames(
              "w-full mr-2 py-2 pl-2 focus:outline-none",
              valueClassName,
              editingClassName
            )}
            style={{
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.2)",
            }}
            // textPosition="left"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type={type}
          />
        )}
        <div className="flex gap-4">
          {!isDisabled && (
            <AsIconButton
              variant="ghost"
              size="xs"
              type="submit"
              leadingIcon={"FeatherCheck"}
            />
          )}
        </div>
      </form>
    </div>
  ) : (
    <div className={`flex flex-col ${containerClassName}`}>
      <p className="text-zinc-500 text-sm">{label}</p>
      <div
        className={classNames("flex items-center", {
          // "opacity-50": isDisabled,
        })}
      >
        <p
          className={classNames("w-full overflow-auto py-2", valueClassName)}
          key={value}
        >
          {displayValue ||
            (type === "phone" ? formatPhoneNumber(value) : value)}
        </p>
        {!isDisabled && (
          <AsIconButton
            variant="ghost"
            size="xs"
            onClick={() => setEditing(true)}
            leadingIcon={"FeatherPen"}
          />
        )}
      </div>
    </div>
  );
};
