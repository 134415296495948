import { selectSchedules } from "app/redux/schedulesSlice";
import { selectShops } from "app/redux/shopsSlice";
import { OtherEventTypes } from "app/schedules/useSchedules";
import { useQuery } from "app/utils/useQuery";
import {
  isWithinInterval,
  addMinutes,
  areIntervalsOverlapping,
  interval,
  isEqual,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { find } from "lodash";
import { useSelector } from "react-redux";

export const useCustomEvent = ({
  type,
  shop,
  start,
  end,
  employee,
  appointment,
}: any) => {
  const query = useQuery();
  const shops = useSelector(selectShops);
  const schedules = useSelector(selectSchedules);
  const shopId = query.get("shopId");
  const isOffTime = type === OtherEventTypes.OFF;
  const isABlockedTime = type === OtherEventTypes.BLOCKED;
  const isCurrentShop = Number(shopId) === shop?.id;
  const shopTimezone = find(shops, { id: Number(query.get("shopId")) })
    ?.timezone.timeZoneId;

  // get current date based on the shop timezone
  const now = toZonedTime(new Date(), shopTimezone);
  // check if the current time is within the appointment interval
  const isAnActiveTime = isWithinInterval(now, { start: start, end: end });

  const day = query.get("date") || "";
  const schedulesFromDay = schedules[day];

  const currentAppointmentId = appointment?.id || appointment?.phantomId;
  const curAppointmentInterval = !!appointment
    ? interval(
        appointment.startTime,
        addMinutes(appointment.startTime, appointment.duration || 0)
      )
    : undefined;
  const employeeSchedules = find(schedulesFromDay, {
    employeeId: Number(employee.id),
  })
    ?.appointments.filter(
      (_appointment) =>
        _appointment.id !== currentAppointmentId &&
        _appointment.phantomId !== currentAppointmentId
    )
    .filter((_appointment) => !!_appointment);

  const appointmentsIntervals = employeeSchedules?.map((_appointment) =>
    interval(
      _appointment.startTime,
      addMinutes(_appointment.startTime, _appointment.duration || 0)
    )
  );
  const overlappingAppointments = !!curAppointmentInterval
    ? appointmentsIntervals?.filter(
        (_interval) =>
          !isEqual(curAppointmentInterval.start, _interval.start) &&
          areIntervalsOverlapping(curAppointmentInterval, _interval)
      )
    : [];

  const isOverlappingOther = !!overlappingAppointments?.length;
  const isOverlappingAbove =
    isOverlappingOther &&
    overlappingAppointments.some(
      (_interval) => curAppointmentInterval?.start > _interval.start
    );

  return {
    isOffTime,
    isCurrentShop,
    isABlockedTime,
    isAnActiveTime,
    shopTimezone,
    isOverlappingOther,
    isOverlappingAbove,
  };
};
