import { CrudAppointmentActions } from "app/schedules/useSchedules";

function useEditRepeatAppointment({
  onOpenChange,
  toggleAppointmentDrawer,
  onUpdateThisAppointmentOnly,
  onUpdateAllFutureAppointments,
}: {
  onOpenChange: (isOpen: boolean) => void;
  toggleAppointmentDrawer: (isOpen: boolean, action?: CrudAppointmentActions) => void;
  onUpdateThisAppointmentOnly?: () => void;
  onUpdateAllFutureAppointments?: () => void;
}) {
  const handleThisAppointmentOnly = () => {
    onUpdateThisAppointmentOnly?.();

    toggleAppointmentDrawer(false);
    onOpenChange(false);
  };

  const handleAllFutureAppointments = () => {
    onUpdateAllFutureAppointments?.();

    toggleAppointmentDrawer(false);
    onOpenChange(false);
  };


  return {
    handleThisAppointmentOnly,
    handleAllFutureAppointments,
  };
}

export default useEditRepeatAppointment;
