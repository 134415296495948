import { createAppointment } from "app/redux/appointmentsSlice";
import { selectBusinessServices } from "app/redux/businessServicesSlice";
import { searchClients, selectClients } from "app/redux/clientsSlice";
import { selectEmployees } from "app/redux/employeesSlice";
import { selectShops } from "app/redux/shopsSlice";
import { formatAsUTC } from "app/utils/formatDate";
import { getFormValues } from "app/utils/getFormValues";
import { useQuery } from "app/utils/useQuery";
import {
  addMinutes,
  differenceInMinutes,
  format,
  isValid,
  parse,
  set,
  startOfDay,
} from "date-fns";
import { find } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useCreateAppointmentScreen = ({
  pendingAppointment,
  setPendingAppointment,
  toggleAppointmentDrawer,
  getSchedulesOfEmployees,
}: {
  pendingAppointment: any;
  setPendingAppointment: (pendingAppointment: any) => void;
  toggleAppointmentDrawer: (open: boolean) => void;
  getSchedulesOfEmployees: ({ currentDate }: { currentDate?: Date }) => void;
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const employees = useSelector(selectEmployees);
  const { clients } = useSelector(selectClients);
  const employee = find(
    employees,
    (employee) => employee.id === pendingAppointment?.employee.id
  );
  const businessServices = useSelector(selectBusinessServices);
  const [selectedDate, setSelectedDate] = useState(pendingAppointment?.start);
  const [isDateInputFocused, setIsDateInputFocused] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [availableServices, setAvailableServices] = useState(businessServices);
  const [selectedServices, setSelectedServices] = useState<any>([]);
  const [serviceSelectValue, setServiceSelectValue] = useState(null);
  const [editingEmployeeInServiceIndex, setEditingEmployeeInServiceIndex] =
    useState<number | null>(null);
  const employeeSelectRef = useRef<any>(null);

  const onHandleClientChange = (selectedOption: any) => {
    setSelectedClient({
      ...selectedOption,
      id: selectedOption.value,
      name: selectedOption.label,
    });
  };

  const onHandleAddService = (selectedOption: any) => {
    const employeeService = find(
      employee!.services,
      (service) => service.businessService.id === selectedOption.value
    );

    setSelectedServices([...selectedServices, employeeService]);

    const updatedAvailableServices = availableServices.map((category) => ({
      ...category,
      services: category.services.filter(
        (service: any) => service.id !== selectedOption.value
      ),
    }));

    setAvailableServices(updatedAvailableServices);
    setServiceSelectValue(null); // Clear the Select component
  };

  const handleEmployeeChange = (serviceIndex: number, selectedOption: any) => {
    const updatedServices = [...selectedServices];
    const newEmployee = employees.find(
      (emp) => emp.id === selectedOption.value
    );
    const newEmployeeService = newEmployee?.services.find(
      (service) =>
        service.businessService.id ===
        updatedServices[serviceIndex].businessService.id
    );
    updatedServices[serviceIndex] = {
      ...updatedServices[serviceIndex],
      employee: newEmployee,
      id: newEmployeeService.id,
      price: newEmployeeService.price,
      priceCents: newEmployeeService.priceCents,
      status: newEmployeeService.status,
      duration: newEmployeeService.duration,
    };
    setSelectedServices(updatedServices);
    setTimeout(() => {
      setEditingEmployeeInServiceIndex(null);
    }, 0);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const { startTime } = getFormValues(event.target);
    if (!selectedClient) {
      toast.error("Please select a client");
      return;
    }
    if (selectedServices.length === 0) {
      toast.error("Please add at least one service");
      return;
    }

    try {
      const formattedDate = format(selectedDate, "yyyy-MM-dd");
      const parsedDate = parse(formattedDate, "yyyy-MM-dd", new Date());
      const parsedTime = parse(startTime, "HH:mm", parsedDate);

      // Combine the date and time into a single Date object
      let currentStartDateTime = set(parsedDate, {
        hours: parsedTime.getHours(),
        minutes: parsedTime.getMinutes(),
      });

      let appointmentGroupId = null;

      // Iterate over each service
      for (const service of selectedServices) {
        // Calculate the end time by adding the service duration to currentStartDateTime
        const currentEndDateTime = addMinutes(
          currentStartDateTime,
          service.duration
        );

        // Format the startDateTime and endDateTime without changing the time
        const formattedStartTime = format(
          currentStartDateTime,
          "yyyy-MM-dd HH:mm"
        );
        const formattedEndTime = format(currentEndDateTime, "yyyy-MM-dd HH:mm");

        // Create the payload for the current service
        const payload: any = {
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          name: selectedClient.name,
          email: selectedClient.email,
          shopId: Number(query.get("shopId")), // Temporary solution until multiple shops are supported
          employeeId: service.employee.id,
          serviceIds: [service.id],
          clientId: selectedClient.id,
          appointmentGroupId, // Same group for all services
        };

        // Dispatch the action to create the appointment
        const response = await dispatch(
          createAppointment(payload) as any
        ).unwrap();

        // If this is the first appointment, set the appointmentGroupId for subsequent appointments
        if (!appointmentGroupId && response.appointmentGroup?.id) {
          appointmentGroupId = response.appointmentGroup.id;
        }

        // Set the next service's start time to the current service's end time
        currentStartDateTime = currentEndDateTime;
      }

      toggleAppointmentDrawer(false);
      setPendingAppointment(null);
      getSchedulesOfEmployees({
        currentDate: startOfDay(selectedDate),
      });
      toast.success("Appointment created successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to create appointments. Please try again.");
    }
  };

  const onRemoveService = (index: number) => {
    const updatedServices = [...selectedServices];
    updatedServices.splice(index, 1); // Remove the service at the specified index

    setSelectedServices(updatedServices);
  };

  const handleClientSearch = async (inputValue: string, callback: any) => {
    if (inputValue.length > 2) {
      try {
        const response = await dispatch(
          searchClients(inputValue) as any
        ).unwrap();
        const results =
          response.clients?.map((c: any) => ({
            value: c.id,
            label: c.name,
            phone: c.phone,
            email: c.email,
            createdAt: c.createdAt,
            avatar: c.avatar,
          })) || [];
        callback(results);
      } catch (error) {
        console.error("Error searching clients:", error);
      }
    } else {
      return [];
    }
  };

  const onDateChange = useCallback(
    (date: Date) => {
      if (!isValid(date)) {
        return;
      }
      const formattedDate = formatAsUTC(date, "yyyy-MM-dd");
      const formattedStartTime = format(pendingAppointment?.start, "HH:mm");
      const formattedEndTime = format(
        addMinutes(
          pendingAppointment?.start,
          differenceInMinutes(
            pendingAppointment?.end,
            pendingAppointment?.start
          )
        ),
        "HH:mm"
      ); // Add 30 minutes to the start time
      const formattedDateStartTime = `${formattedDate}T${formattedStartTime}`; // Combine both
      const formattedDateEndTime = `${formattedDate}T${formattedEndTime}`; // Combine both
      const dateTimeStartObject = new Date(formattedDateStartTime);
      const dateTimeEndObject = new Date(formattedDateEndTime);

      setSelectedDate(formattedDate);
      setPendingAppointment({
        ...pendingAppointment,
        start: dateTimeStartObject,
        end: dateTimeEndObject,
      });
      getSchedulesOfEmployees({ currentDate: startOfDay(dateTimeStartObject) });
      query.delete("date");
      query.set("date", formattedDate);
      navigate(`?${query.toString()}`);
    },
    [pendingAppointment]
  );

  const onTimeChange = useCallback(
    (startTime: string) => {
      if (
        !isValid(pendingAppointment?.start) ||
        !isValid(pendingAppointment?.end)
      ) {
        return;
      }

      // Format the selected date
      const formattedDate = format(new Date(selectedDate), "yyyy-MM-dd");

      // Combine date with new start and end times
      const formattedStartDateTime = `${formattedDate}T${startTime}`;
      const formattedDateEndTime = `${formattedDate}T${format(
        addMinutes(
          formattedStartDateTime,
          differenceInMinutes(
            pendingAppointment?.end,
            pendingAppointment?.start
          )
        ),
        "HH:mm"
      )}`; // Combine both

      // Convert to Date objects
      const dateTimeStartObject = new Date(formattedStartDateTime);
      const dateTimeEndObject = new Date(formattedDateEndTime);

      // Update the pending appointment state
      setPendingAppointment({
        ...pendingAppointment,
        start: dateTimeStartObject,
        end: dateTimeEndObject,
      });
    },
    [pendingAppointment]
  );

  useEffect(() => {
    return () => {
      setPendingAppointment(null);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        employeeSelectRef.current &&
        !employeeSelectRef.current.contains(event.target as Node)
      ) {
        setEditingEmployeeInServiceIndex(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [employeeSelectRef]);

  useEffect(() => {
    if (employee && businessServices.length > 0) {
      const updatedAvailableServices = businessServices.map((category) => ({
        ...category,
        services: category.services.map((service: any) => {
          const employeeService = employee.services.find(
            (empService) => empService.businessService.id === service.id
          );
          return {
            ...service,
            status: employeeService ? employeeService.status : "inactive",
          };
        }),
      }));
      setAvailableServices(updatedAvailableServices);
    }
  }, [employee, businessServices]);

  return {
    employee,
    employees,
    clients,
    isDateInputFocused,
    setIsDateInputFocused,
    onSubmit,
    onHandleClientChange,
    selectedClient,
    setSelectedClient,
    selectedServices,
    onHandleAddService,
    selectedDate,
    setSelectedDate,
    serviceSelectValue,
    availableServices,
    editingEmployeeInServiceIndex,
    setEditingEmployeeInServiceIndex,
    handleEmployeeChange,
    employeeSelectRef,
    onRemoveService,
    handleClientSearch,
    onDateChange,
    onTimeChange,
  };
};
