import { format } from "date-fns";
import { useMemo } from "react";

const useStopRepeatAppointment = ({
  blockTime,
  onOpenChange,
  onSave,
}: {
  blockTime: any;
  onOpenChange: (isOpen: boolean) => void;
  onSave?: () => Promise<void>;
}) => {
  const blockTimeDetails = useMemo(() => {
    if (!blockTime) {
      return {
        date: "",
        time: "",
        employee: null,
        name: "",
        shop: {},
      };
    }

    const { start, blockTimes, employee, blockTimeData } = blockTime;
    const formattedDate = start ? format(start, "EEE") : "";
    const formattedTime = start ? format(start, "h:mm a") : "";

    return {
      date: formattedDate,
      time: formattedTime,
      employee,
      name: blockTimeData?.name,
    };
  }, [blockTime]);

  const confirmStopRepeat = () => {
    onOpenChange(false);
    onSave?.();
  };

  return {
    blockTimeDetails,
    confirmStopRepeat,
  };
};

export default useStopRepeatAppointment;
