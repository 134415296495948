import { format } from "date-fns";
import { useMemo } from "react";

const useStopRepeatAppointment = ({
  appointmentGroup,
  onOpenChange,
  onSave,
}: {
  appointmentGroup: any;
  onOpenChange: (isOpen: boolean) => void;
  onSave?: () => Promise<void>;
}) => {
  const appointmentDetails = useMemo(() => {
    if (!appointmentGroup) {
      return {
        selectedClient: null,
        date: "",
        time: "",
        selectedServices: [],
      };
    }

    const { startTime, appointments } = appointmentGroup;

    const formattedDate = startTime
      ? format(new Date(startTime), "EEE, MMM yyyy")
      : "";
    const formattedTime = startTime
      ? format(new Date(startTime), "h:mm a")
      : "";
    const client = appointments[0].client;
    const services = (appointments || []).reduce(
      (acc: any, appointment: any) => {
        return [...acc, ...appointment.services];
      },
      []
    );

    return {
      selectedClient: client,
      date: formattedDate,
      time: formattedTime,
      selectedServices: services,
    };
  }, [appointmentGroup]);

  const confirmStopRepeat = () => {
    onOpenChange(false);
    onSave?.();
  };

  return {
    confirmStopRepeat,
    appointmentDetails,
  };
};

export default useStopRepeatAppointment;
