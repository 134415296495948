import { RecurrenceFrequency } from "app/redux/schedulesSlice";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";

function useMakeRepeatAppointment({
  appointmentGroup,
  onOpenChange,
  onSave,
}: {
  appointmentGroup: any;
  onOpenChange: (isOpen: boolean) => void;
  onSave?: (frequency: RecurrenceFrequency, interval: number) => Promise<void>;
}) {
  const [isRepeating, setIsRepeating] = useState<boolean>(true);
  const [weeks, setWeeks] = useState(4);

  const appointmentDetails = useMemo(() => {
    if (!appointmentGroup) {
      return {
        selectedClient: null,
        date: "",
        time: "",
        selectedServices: [],
      };
    }

    const { startTime, appointments } = appointmentGroup;

    const formattedDate = startTime ? format(startTime, "EEE") : "";
    const formattedTime = startTime ? format(startTime, "h:mm a") : "";
    const client = appointments[0].client;
    const services = (appointments || []).reduce(
      (acc: any, appointment: any) => {
        return [...acc, ...appointment.services];
      },
      []
    );

    return {
      selectedClient: client,
      date: formattedDate,
      time: formattedTime,
      selectedServices: services,
    };
  }, [appointmentGroup]);

  const confirmRepeat = () => {
    if (weeks < 1) {
      return toast.error("Appointment frequency has to be at least 1 week");
    }
    if (!isRepeating) {
      return onOpenChange(false);
    }
    onOpenChange(false);
    onSave?.(RecurrenceFrequency.WEEKLY, weeks);
  };

  return {
    isRepeating,
    setIsRepeating,
    weeks,
    setWeeks,
    appointmentDetails,
    confirmRepeat,
  };
}

export default useMakeRepeatAppointment;
