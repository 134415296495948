import * as React from "react";
import {
  Button,
  Divider,
  Input,
  Select,
  SelectItem,
  Spinner,
} from "@nextui-org/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useBusinessServices } from "./useBusinessServices";
import { durationList } from "app/redux/businessServicesSlice";
import { InputEdit } from "app/components/inputEdit/InputEdit";
import { find, truncate } from "lodash";
import { AddCategoryModal } from "./components/addCategoryModal/addCategoryModal";
import { AddServiceModal } from "./components/addServiceModal/addServiceModal";
import { Collapse } from "react-collapse";
import { AsButton, AsButtonLeft, AsIconButton, AsInput } from "app/subframe";
import { EmptyState } from "app/components/emptyState";

export interface BusinessServicesProps {}

export const BusinessServices: React.FC<BusinessServicesProps> = (props) => {
  const {
    isLoading,
    businessServices,
    onServicePress,
    selectedServiceParams,
    selectedService,
    onServiceNameSave,
    duration,
    setDuration,
    price,
    setPrice,
    onUpdateService,
    isDirty,
    isUpdating,
    onServiceCategoryNameSave,
    showAddCategoryModal,
    setShowAddCategoryModal,
    showAddServiceModal,
    setShowAddServiceModal,
    onAddServiceClick,
    serviceCategoryIdForNewService,
    openCategoryId,
    setOpenCategoryId,
    onArchiveServiceCategory,
    onArchiveService,
  } = useBusinessServices();

  if (isLoading) {
    return <Spinner size="lg" />;
  }

  return (
    <>
      <AddCategoryModal
        showAddCategoryModal={showAddCategoryModal}
        setShowAddCategoryModal={setShowAddCategoryModal}
        setOpenCategoryId={setOpenCategoryId}
      />
      <AddServiceModal
        showAddServiceModal={showAddServiceModal}
        setShowAddServiceModal={setShowAddServiceModal}
        serviceCategoryIdForNewService={serviceCategoryIdForNewService}
      />
      <div className="flex">
        <div className="border-r-millet-400 border-r-2 h-screen bg-millet-200 w-full max-w-[240px] p-4 ">
          <AsButton
            variant="neutral-secondary"
            className="flex gap-2 w-full mb-3"
            onClick={() => setShowAddCategoryModal(true)}
          >
            <PlusIcon className="w-4 h-4 stroke-black" />
            <p className="text-body-bold font-body-bold text-neutral-900">
              Add Category
            </p>
          </AsButton>

          <div className="flex flex-col gap-2">
            {businessServices.map((serviceCategory) => (
              <div key={serviceCategory.id}>
                <AsButton
                  variant="ghost"
                  className="w-full border-none flex justify-between rounded-sm text-left"
                  onClick={() =>
                    setOpenCategoryId(
                      openCategoryId === serviceCategory.id
                        ? null
                        : serviceCategory.id
                    )
                  }
                  trailingIcon={
                    openCategoryId === serviceCategory.id
                      ? "FeatherChevronUp"
                      : "FeatherChevronDown"
                  }
                  text={serviceCategory.name}
                />
                <Collapse isOpened={openCategoryId === serviceCategory.id}>
                  {serviceCategory.services.map((service: any) => (
                    <AsButtonLeft
                      variant="ghost"
                      key={service.id}
                      size="sm"
                      className="ml-3 w-11/12 justify-start text-left outline-none"
                      onClick={() =>
                        onServicePress({
                          serviceId: service.id,
                          categoryName: serviceCategory.name,
                          categoryId: serviceCategory.id,
                        })
                      }
                    >
                      <p className="text-sm">
                        {" "}
                        {truncate(service.title, {
                          length: 20,
                          omission: "..",
                        })}
                      </p>
                    </AsButtonLeft>
                  ))}
                  <AsButtonLeft
                    variant="ghost"
                    className="ml-3 w-11/12 justify-start"
                    onClick={() => onAddServiceClick(serviceCategory.id)}
                    leadingIcon={"FeatherPlus"}
                  >
                    <p className="text-sm font-semibold">Add Service</p>
                  </AsButtonLeft>
                </Collapse>
              </div>
            ))}
          </div>
        </div>

        {selectedService && (
          <div className="flex flex-col w-full p-4 items-center">
            <div className="flex flex-col gap-6 w-full max-w-[520px]">
              <div className="flex items-center justify-between w-full">
                <InputEdit
                  defaultValue={selectedServiceParams?.category || ""}
                  name="name"
                  containerClassName="w-full"
                  valueClassName="text-body-bold font-body-bold text-neutral-900 w-full"
                  onSave={(value) => onServiceCategoryNameSave(value)}
                />
                <AsIconButton
                  variant="destructive-ghost"
                  leadingIcon={"FeatherTrash"}
                  onClick={() => {
                    onArchiveServiceCategory(
                      Number(selectedServiceParams.categoryId)
                    );
                  }}
                />
              </div>
              <div className="flex flex-col border rounded p-4 border-neutral-50">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center">
                    <InputEdit
                      defaultValue={selectedService.title}
                      name="title"
                      valueClassName="text-body-bold font-body-bold text-neutral-900"
                      onSave={onServiceNameSave}
                      containerClassName="w-full"
                    />
                    <AsIconButton
                      variant="destructive-ghost"
                      leadingIcon={"FeatherTrash"}
                      onClick={() => {
                        onArchiveService(Number(selectedServiceParams.service));
                      }}
                    />
                  </div>
                  <Divider className="mb-6" />
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex w-full justify-between items-center">
                    <p className="text-body-bold font-body-bold text-neutral-900">
                      Duration
                    </p>
                    <Select
                      variant="bordered"
                      className="max-w-[120px] bg-transparent"
                      classNames={{
                        trigger:
                          "border-[#F4F4F5] rounded shadow-none py-6 border",
                      }}
                      value={duration.duration}
                      selectedKeys={[String(duration.duration)]}
                      onChange={(event) => {
                        setDuration({
                          duration: Number(event.target.value),
                          label: find(durationList, {
                            duration: Number(event.target.value),
                          }),
                        });
                      }}
                    >
                      {durationList.map(({ duration, label }) => (
                        <SelectItem key={String(duration)} value={duration}>
                          {label}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                  <div className="flex w-full justify-between items-center">
                    <div className="w-full">
                      <p className="text-body-bold font-body-bold text-neutral-900">
                        Price
                      </p>
                    </div>
                    <div className="max-w-[120px]">
                      <AsInput
                        leading="$"
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                  </div>
                  <AsButton
                    type="submit"
                    variant="neutral-secondary"
                    size="md"
                    className="w-[200px] self-end"
                    onClick={() => onUpdateService(selectedService.id)}
                    disabled={!isDirty() || isUpdating}
                    loader={isUpdating}
                  >
                    Update
                  </AsButton>
                </div>
              </div>
            </div>
          </div>
        )}

        {businessServices.length === 0 && (
          <div className="flex flex-col gap-4 justify-center items-center h-[75dvh] w-full">
            <EmptyState />
            <AsButton
              text={"Add your first services"}
              onClick={() => setShowAddCategoryModal(true)}
            />
          </div>
        )}
      </div>
    </>
  );
};
